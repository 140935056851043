import React from "react"
import { AnswerType, IAnswerDetails } from "../../../fetchers/answerFetcher/interfaces"
import { ReactComponent as CopyIcon } from "@queue-dev/ui-components/dist/assets/icons/copy.svg"
import TranscriptionAccordion from "../TranscriptionAccordion/TranscriptionAccordion"
import styled from "styled-components"
import {
    AudioPlayer,
    Button,
    BUTTON_TYPE,
    EllipsisText,
    Flex,
    FlexColumn,
    Toast,
} from "@queue-dev/ui-components"

type IAnswerDetailsProps = Pick<
    IAnswerDetails,
    "question" | "expertName" | "workspaceName" | "answer"
>

const QuestionContent = styled.p`
    padding: 8px 12px;
    border-radius: 8px;
    background: rgb(129 228 255 / 10%);
    border: 1px solid #ccc;
    font-weight: 300;
`

const AnswerDetails = ({ question, workspaceName, expertName, answer }: IAnswerDetailsProps) => {
    const copyAudioUrl = async () => {
        if (answer?.recordURL) {
            await navigator.clipboard.writeText(answer?.recordURL || "")
            Toast.success("Copied to clipboard")
        }
    }

    return (
        <FlexColumn gap={30}>
            <FlexColumn gap={5}>
                <h4>Question</h4>
                <QuestionContent>{question}</QuestionContent>
            </FlexColumn>
            <FlexColumn gap={5}>
                <h5>Answer</h5>
                <Flex gap={50}>
                    <Flex gap={5} alignItems="center">
                        <b>Expert name:</b>
                        <EllipsisText>{expertName}</EllipsisText>
                    </Flex>
                    <Flex gap={5} alignItems="center">
                        <b>Workspace:</b>
                        <EllipsisText>{workspaceName}</EllipsisText>
                    </Flex>
                </Flex>
                {answer.recordURL && (
                    <Flex gap={20} alignItems="center" my={3}>
                        <Flex>
                            <AudioPlayer src={answer.recordURL} duration={answer.recordDuration!} />
                        </Flex>
                        <Button
                            buttonType={BUTTON_TYPE.LINK}
                            leftIcon={<CopyIcon />}
                            onClick={copyAudioUrl}
                        >
                            Copy URL
                        </Button>
                    </Flex>
                )}

                <TranscriptionAccordion
                    value={answer.contentHTML || answer.content || ""}
                    answerId={answer._id}
                    disabled={answer.answerType === AnswerType.TEXT}
                />
            </FlexColumn>
        </FlexColumn>
    )
}

export default AnswerDetails
