import React, { useContext } from "react"

import { HttpStatusMessageContext, HttpStatusType } from "./HttpStatusMessageProvider"
import { IconButton, Snackbar } from "@mui/material"
import { Close } from "@mui/icons-material"

const HttpStatusMessage: React.FC = () => {
    const { message, messageType, hideMessage } = useContext(HttpStatusMessageContext)

    const backgroundColor = {
        [HttpStatusType.WARN]: "rgba(206,206,85,0.9)",
        [HttpStatusType.ERROR]: "rgba(173,40,40,0.9)",
        [HttpStatusType.SUCCESS]: "rgba(38,159,38,0.9)",
    }

    const style = {
        backgroundColor: backgroundColor[messageType as HttpStatusType],
        color: "black",
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            open={Boolean(message)}
            onClose={hideMessage}
            message={<span>{message}</span>}
            ContentProps={{
                style,
            }}
            action={[
                <IconButton key="close" aria-label="Close" color="inherit" onClick={hideMessage}>
                    <Close />
                </IconButton>,
            ]}
        />
    )
}

export default HttpStatusMessage
