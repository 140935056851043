import styled from "styled-components"
import { Flex, FlexColumn } from "@queue-dev/ui-components"

export const FormWrapper = styled(FlexColumn)`
    width: 100%;
    overflow: hidden;
`
export const FormContainer = styled(FlexColumn)`
    width: 100%;
    min-width: 380px;
    overflow: hidden;
    padding-top: 10px;
`

export const FormFieldsWrapper = styled(FlexColumn)`
    gap: 40px;
    overflow: hidden;
    flex-shrink: 1;
    padding: 10px 20px 0;
    margin: 0 10px;
`

export const SubmitButtonWrapper = styled(Flex)`
    align-self: stretch;
    justify-content: flex-end;
    align-items: center;
    padding: 24px 32px;
    gap: 20px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.24);
    transition: all 0.3s;
`

export const FlexRow = styled(Flex)`
    justify-content: space-between;
    width: 100%;
    gap: 10px;

    & > * {
        width: 48%;
    }
`

export const Divider = styled(FlexColumn)`
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    :after,
    :before {
        content: "";
        min-width: 1px;
        flex-grow: 1;
        background: #ccc;
    }
    > button {
        border: 1px solid #ccc;
        padding: 2px;
    }
`

export const TestPanel = styled(Flex)<{ $extend: boolean }>`
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: ${({ $extend }) => ($extend ? 100 : 0)}%;
    padding-top: 8px;
    overflow: hidden;
    transition: flex-basis 0.3s;
`
