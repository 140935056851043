import { ReactComponent as BlogIcon } from "../../assets/platforms/blog.svg"
import { ReactComponent as InstagramIcon } from "../../assets/platforms/instagram.svg"
import { ReactComponent as TwitterIcon } from "../../assets/platforms/twitter.svg"
import { ReactComponent as QuoraIcon } from "../../assets/platforms/quora.svg"
import { ReactComponent as LinkedinIcon } from "../../assets/platforms/linkedIn.svg"
import LeaderboardIcon from "@mui/icons-material/EmojiEvents"
import { IAsset, InstagramType, Platform } from "../../fetchers/assetsFetcher/interfaces"

export const ASSETS_CONFIG = [
    {
        platform: Platform.LEADERBOARD,
        title: "Leaderboard",
        Icon: LeaderboardIcon,
        extraFields: [
            {
                key: "tldr" as keyof IAsset,
                label: "TLDR",
            },
        ],
    },
    {
        platform: Platform.BLOG,
        title: "Blog",
        Icon: BlogIcon,
        extraFields: [
            {
                key: "title" as keyof IAsset,
                label: "Title",
            },
        ],
    },
    {
        platform: Platform.QUORA,
        title: "Quora",
        Icon: QuoraIcon,
    },
    {
        platform: Platform.LINKEDIN,
        title: "Linkedin",
        Icon: LinkedinIcon,
        media: true,
    },
    {
        platform: Platform.TWITTER,
        title: "Twitter",
        Icon: TwitterIcon,
        media: true,
    },
    {
        platform: Platform.INSTAGRAM,
        type: InstagramType.Reel,
        title: "Instagram - Reel",
        Icon: InstagramIcon,
        media: true,
    },
    {
        platform: Platform.INSTAGRAM,
        type: InstagramType.Square,
        title: "Instagram - Square",
        Icon: InstagramIcon,
        media: true,
    },
]
