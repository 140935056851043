export interface IAsset {
    _id?: string
    __v?: number
    answerId?: string
    userId: string
    workspaceId: string
    platform: Platform
    type?: InstagramType
    title?: string
    tldr?: string
    status?: AssetStatus
    content: string
    contentText: string
    media?: Media | null
    contentUpdatedAt?: Date
    contentUpdatedBy?: string
    createdAt: Date
    updatedAt: Date
}

export type SaveAsset = Omit<IAsset, "createdAt" | "updatedAt">
export type NewAsset = Omit<IAsset, "_id" | "__v" | "createdAt" | "updatedAt">

export enum AssetStatus {
    READY_TO_PUBLISH = "ready_to_publish",
    PUBLISHED = "published",
}
export enum Platform {
    BLOG = "blog",
    QUORA = "quora",
    TWITTER = "twitter",
    LINKEDIN = "linkedin",
    INSTAGRAM = "instagram",
    LEADERBOARD = "leaderboard",
}

export enum InstagramType {
    Reel = "reel",
    Square = "square",
}

export interface Media {
    src?: string
    type?: MediaType
}

export enum MediaType {
    Image = "image",
    Audio = "audio",
    Video = "video",
}
