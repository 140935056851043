import { MouseEvent } from "react"
import { getSocialAuthURL } from "../../utils/auth"
import style from "./Login.module.scss"
import clsx from "clsx"

const Login = () => {
    const handleGoogleClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        window.location.href = getSocialAuthURL("google-oauth2")
    }

    return (
        <div className={clsx(style.loginContainer)}>
            <div className={clsx(style.loginContainerWrapper)}>
                <h2>Sign In</h2>
                <p className={clsx(style.loginContainerWrapperTitle)}>Welcome! Please choose</p>
                <button
                    className={clsx(style.loginContainerWrapperSocials)}
                    onClick={handleGoogleClick}
                >
                    <span>Continue with Google</span>
                </button>
            </div>
        </div>
    )
}
export default Login
