const {
    REACT_APP_AUTH0_AUDIENCE,
    REACT_APP_AUTH0_LOGIN_REDIRECT_URI,
    REACT_APP_AUTH0_USER_SCOPE,
    REACT_APP_AUTH0_DOMAIN,
    REACT_APP_AUTH0_CLIENT_ID,
} = process.env
export const AUTH0_DOMAIN = REACT_APP_AUTH0_DOMAIN ?? "dev-yiv-84qm.us.auth0.com"
export const AUTH0_CLIENT_ID = REACT_APP_AUTH0_CLIENT_ID ?? "d2HXZN3TCdiu920lriUD2yEhjgVtFlbQ"
export const AUTH0_LOGIN_REDIRECT_URI = REACT_APP_AUTH0_LOGIN_REDIRECT_URI ?? "/authenticate"
export const AUTH0_USER_SCOPE =
    REACT_APP_AUTH0_USER_SCOPE ?? "openid email profile read:email_templates"
export const AUTH0_AUDIENCE = REACT_APP_AUTH0_AUDIENCE ?? "https://dev-yiv-84qm.us.auth0.com"
