import styled from "styled-components"

export const TextArea = styled.div`
    width: 100%;
    font-size: inherit !important;

    & fieldset {
        border: none;
    }

    .ql-editor {
        margin-bottom: 0;
        font: inherit;

        ul,
        ol {
            padding-left: 0.5em;
        }

        p {
            line-height: 24px;
            padding-block: 4px;
        }
    }

    .ql-blank::before {
        font-style: initial !important;
    }

    .ql-tooltip,
    .ql-picker-options {
        background-color: #ffffff !important;
        border-radius: 20px;

        .ql-tooltip-editor input[type="text"] {
            color: #00022b;
        }

        .ql-toolbar {
            .ql-formats {
                .ql-active {
                    .ql-stroke {
                        stroke: #262ffc !important;
                    }

                    .ql-fill {
                        fill: #262ffc !important;
                    }

                    &.ql-picker-label {
                        color: ${({ theme }) => theme.colors.qorEdgeOn} !important;
                    }
                }

                .ql-picker-label:hover,
                .ql-picker-item:hover,
                .ql-picker-label:hover svg polygon,
                .ql-picker-item:hover svg polygon {
                    fill: ${({ theme }) => theme.colors.qorEdgeOn} !important;
                    stroke: ${({ theme }) => theme.colors.qorEdgeOn} !important;
                    color: ${({ theme }) => theme.colors.qorEdgeOn} !important;
                }

                button {
                    .ql-stroke {
                        stroke: #00022b !important;
                    }

                    .ql-fill {
                        fill: #00022b !important;
                    }

                    &:hover {
                        .ql-stroke {
                            stroke: #262ffc !important;
                        }

                        .ql-fill {
                            fill: #262ffc !important;
                        }
                    }
                }
            }
        }
    }
    /* snow url toolbar overrides*/
    &.ql-snow .ql-tooltip {
        border-radius: 4px;
        font-size: 14px;
    }
    &.ql-snow .ql-tooltip[data-mode="link"]::before {
        display: none;
    }
    &.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
        color: var(--q-colors-primary);
        margin-left: 12px;
    }
    &.ql-snow .ql-tooltip.ql-editing a.ql-action:hover::after {
        color: var(--q-colors-text-primary);
    }
    &.ql-snow .ql-tooltip.ql-editing input[type="text"] {
        width: 364px;
        background: rgba(242, 241, 238, 0.6);
        border-radius: 4px;
        box-shadow: rgba(15, 15, 15, 0.1) 0 0 0 1px inset;
        border: none;
        outline: none;
        height: auto;
        padding: 6px;
        vertical-align: middle;
    }
    /*override the "edit link" toolbar */
    &.ql-snow .ql-tooltip::before {
        content: "";
    }
`
