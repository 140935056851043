import ReactDOM from "react-dom/client"
import { LOGROCKET_APP } from "./config/global.consts"
import Public from "./Public"
import LogRocket from "logrocket"

if (LOGROCKET_APP) {
    LogRocket.init(`iraia3/${LOGROCKET_APP}`)
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(<Public />)
