import { BaseHttpFetcher } from "../baseFetcher/baseFetcher"
import { ITriggerData } from "./interfaces"

export class IntegromatTriggersFetcher extends BaseHttpFetcher {
    async getAll(): Promise<ITriggerData[]> {
        try {
            return await this.get(`/integromat/triggers`)
        } catch (err) {
            throw err
        }
    }

    getTriggerUrl(trigger: ITriggerData): string {
        return `${this.axios.defaults.baseURL}integromat/triggers/${trigger.key}`
    }
}
