import React, { useState } from "react"
import ArticleIcon from "@mui/icons-material/Article"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import { Button } from "@mui/material"
import BaseRichTextEditor from "../../RichTextEditor/RichTextEditor"
import styled from "styled-components"
import { Flex, FlexColumn } from "@queue-dev/ui-components"
import { useUpdateAnswerMutation } from "../../../fetchers/answerFetcher/mutations"

interface ITranscriptionProps {
    value: string
    disabled: boolean
    answerId: string
}

const RichTextEditor = styled(BaseRichTextEditor)`
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 10px;
`

const TranscriptionAccordion = ({
    value: defaultValue,
    disabled,
    answerId,
}: ITranscriptionProps) => {
    const [newValue, setNewValue] = useState<{ html: string; text: string }>()
    const { mutate: updateAnswer, isLoading: isUpdating } = useUpdateAnswerMutation()

    const handleSaveClicked = () => {
        updateAnswer({
            _id: answerId,
            contentHTML: newValue?.html,
            content: newValue?.text,
            contentLength: (newValue?.text || "")
                .split("\n")
                .map((line) => line.split(" "))
                .flat().length,
        })
    }

    return (
        <Accordion defaultExpanded={!defaultValue}>
            <AccordionSummary>
                <Flex gap={10} alignItems="center" style={{ minHeight: 38 }}>
                    <ArticleIcon color="inherit" />
                    {disabled ? "Raw user answer" : "Transcription"}
                </Flex>
            </AccordionSummary>
            <AccordionDetails>
                <FlexColumn gap={15}>
                    <RichTextEditor
                        defaultValue={defaultValue}
                        onChange={(html, text) => setNewValue({ html, text })}
                        readonly={disabled}
                    />
                    {!disabled && (
                        <Button
                            variant="contained"
                            onClick={handleSaveClicked}
                            disabled={!newValue?.text || isUpdating}
                            style={{ alignSelf: "flex-start" }}
                        >
                            Save
                        </Button>
                    )}
                </FlexColumn>
            </AccordionDetails>
        </Accordion>
    )
}
export default TranscriptionAccordion
