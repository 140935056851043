import React, { ChangeEventHandler, useState } from "react"
import { ReactComponent as PreviewImage } from "./demo-image.svg"
import { Button, CircularProgress, Typography } from "@mui/material"
import { CloudUpload } from "@mui/icons-material"
import { Flex, FlexColumn } from "@queue-dev/ui-components"

interface IUploadStepProps {
    onBack: () => void
    onUpload: (file: File) => Promise<void>
}

const UploadStep = ({ onBack, onUpload }: IUploadStepProps) => {
    const [statusLabel, setStatusLabel] = useState("")
    const [isUploading, setIsUploading] = useState(false)

    const handleFileUpload: ChangeEventHandler<HTMLInputElement> = async (e) => {
        const file = e.target.files?.[0]
        e.target.value = ""
        if (
            !file ||
            file.type.split("/")[1] !== "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
            setStatusLabel("Please upload only xlsx files")
            return
        }
        try {
            setIsUploading(true)
            await onUpload(file)
        } catch (e) {
            setStatusLabel("Error occurred while uploading questions")
        } finally {
            setIsUploading(false)
        }
    }

    return (
        <FlexColumn>
            <Typography variant="body2">
                Please attach a spreadsheet containing questions and the users assigned to them.
                <br />
                For each user, please provide their email address, name, and job title.
                <br />
                <a
                    href="https://docs.google.com/spreadsheets/d/1m5yovnuPGr75DCZWIylBCBoAKx-XdK0F_ZSusbSDQWw/edit?usp=sharing"
                    target="_blank"
                    style={{ marginInlineEnd: 4 }}
                >
                    This file
                </a>
                can be used as a template.
            </Typography>
            <PreviewImage style={{ height: "auto", width: "100%", margin: "20px 0" }} />
            <Typography
                style={{
                    margin: "10px 0 0 0",
                }}
                variant="caption"
                display="block"
            >
                {statusLabel}
            </Typography>
            <Flex gap={30}>
                <Button variant="text" onClick={onBack} color="inherit">
                    Back
                </Button>
                <Button
                    variant="contained"
                    startIcon={
                        isUploading ? (
                            <CircularProgress color="inherit" size={20} />
                        ) : (
                            <CloudUpload />
                        )
                    }
                    component="label"
                    disabled={isUploading}
                >
                    Choose a file
                    <input type="file" accept=".xlsx" hidden onChange={handleFileUpload} />
                </Button>
            </Flex>
        </FlexColumn>
    )
}

export default UploadStep
