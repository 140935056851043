import React, { useMemo, useState } from "react"
import { ASSETS_CONFIG } from "../assets-config"
import { Accordion, AccordionDetails, AccordionSummary, Button } from "@mui/material"
import styled from "styled-components"
import BaseRichTextEditor from "../../RichTextEditor/RichTextEditor"
import { AssetStatus, IAsset, MediaType } from "../../../fetchers/assetsFetcher/interfaces"
import { useSaveAssetMutation } from "../../../fetchers/assetsFetcher/mutations"
import { Flex, FlexColumn, LoadableImage, Toast } from "@queue-dev/ui-components"
import { useUploadMediaToAsset } from "../../../fetchers/s3Fetcher/mutations"
import ReadyToPublishSwitch from "./ReadyToPublishSwitch"
import { MAX_IMAGE_SIZE, useImagesProcessor } from "../../../hooks/use-images-processor"
import { extractTextFromHTML } from "../Assets"

type IAssetAccordionProps = (typeof ASSETS_CONFIG)[number] & {
    assetData: IAsset | undefined
    answerId: string
    userId: string | undefined
    workspaceId: string | undefined
}

const RichTextEditor = styled(BaseRichTextEditor)`
    font-weight: 400;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
`
const AssetAccordion = ({
    platform,
    type,
    title,
    Icon,
    extraFields,
    media,
    assetData,
    answerId,
    userId,
    workspaceId,
}: IAssetAccordionProps) => {
    const { isUploading: isUploadingImages, answerImagesProcessor } = useImagesProcessor(
        assetData?._id || ""
    )
    const [changes, setChanges] = useState<{ [key: string]: { html: string; text: string } }>({})

    const { mutate: saveAsset, isLoading: isSaving } = useSaveAssetMutation()
    const { mutateAsync: uploadFile, isLoading: isUploadingFile } = useUploadMediaToAsset(
        assetData?._id || ""
    )
    const isVisibleForUser =
        !!assetData?.status &&
        [AssetStatus.READY_TO_PUBLISH, AssetStatus.PUBLISHED].includes(assetData?.status)

    const isUploading = useMemo(
        () => isUploadingImages || isUploadingFile,
        [isUploadingFile, isUploadingImages]
    )

    const isSwitchEnabled = useMemo(() => {
        return (
            assetData?.content &&
            (!extraFields || extraFields.every(({ key }) => assetData[key])) &&
            assetData.status !== AssetStatus.PUBLISHED
        )
    }, [assetData])

    const fields = useMemo(
        () => (extraFields || []).concat({ key: "content", label: "Asset content" }),
        [extraFields]
    )

    const isSaveButtonDisabled = useMemo(() => {
        return (
            isUploading ||
            isSaving ||
            fields.some(({ key }) => !assetData?.[key] && !changes[key]?.text)
        )
    }, [isUploading, isSaving, fields, assetData, changes])

    const defaultAssetData = {
        platform,
        type,
        answerId,
        userId,
        workspaceId,
    }

    const handleFileUploaded = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const file = e.target.files?.[0]
            e.target.value = "" // clear input for allow upload same file again
            if (!file) return
            const media = await uploadFile(file)
            saveAsset({
                ...defaultAssetData,
                ...assetData,
                media,
            })
        } catch (e) {
            Toast.error("Uploading file failed")
        }
    }

    const handleClearMedia = async () => {
        saveAsset({
            ...defaultAssetData,
            ...assetData,
            media: null,
        })
    }

    const handleChange = (key: string, value: { html: string; text: string }) => {
        setChanges((prevState) => ({
            ...prevState,
            [key]: value,
        }))
    }

    const handleSaveClicked = async () => {
        const replacedImages = await Promise.all(
            Object.entries(changes).map(async ([key, { html }]) => {
                return [key, await answerImagesProcessor(html)]
            })
        ).then(Object.fromEntries)

        const contentSize = new Blob([replacedImages]).size

        if (contentSize > MAX_IMAGE_SIZE) {
            Toast.error("Content size is too big")
            return
        }
        saveAsset({
            ...defaultAssetData,
            ...assetData,
            ...replacedImages,
            contentText: changes.content?.text || extractTextFromHTML(assetData?.content || ""),
        })
    }

    return (
        <Accordion>
            <AccordionSummary>
                <Flex justifyContent="space-between" flex={1}>
                    <Flex gap={10} alignItems="center">
                        <Icon width={18} height={18} />
                        <span>{title}</span>
                    </Flex>
                    <ReadyToPublishSwitch
                        answerId={answerId}
                        assetId={assetData?._id}
                        disabled={!isSwitchEnabled}
                        defaultChecked={isVisibleForUser}
                    />
                </Flex>
            </AccordionSummary>
            <AccordionDetails>
                <FlexColumn gap={20}>
                    {fields.map(({ key, label }) => (
                        <FlexColumn key={key}>
                            <h6>{label}</h6>
                            <RichTextEditor
                                defaultValue={assetData?.[key as keyof IAsset] as string}
                                onChange={(html, text) => handleChange(key, { html, text })}
                            />
                        </FlexColumn>
                    ))}
                    <Button
                        onClick={handleSaveClicked}
                        variant="outlined"
                        sx={{ alignSelf: "flex-start" }}
                        disabled={isSaveButtonDisabled}
                    >
                        Save
                    </Button>
                </FlexColumn>
                {media && (
                    <FlexColumn margin="40px 0 0" gap={10} alignItems="flex-start">
                        <h6>Media attachment</h6>
                        {assetData?.media?.src && (
                            <>
                                {assetData.media.type &&
                                    [MediaType.Video, MediaType.Audio].includes(
                                        assetData.media.type
                                    ) && (
                                        <video
                                            key={assetData.media.src}
                                            style={{
                                                borderRadius: 15,
                                                marginBottom: 10,
                                                maxHeight: 300,
                                            }}
                                            controls
                                        >
                                            <source src={assetData.media.src} type="video/mp4" />
                                        </video>
                                    )}
                                {assetData.media.type === MediaType.Image && (
                                    <LoadableImage
                                        src={assetData.media.src}
                                        style={{
                                            width: "100%",
                                        }}
                                    />
                                )}
                            </>
                        )}
                        <Flex gap={10}>
                            <input
                                id={`upload-media-${platform}-${type}`}
                                type="file"
                                accept={"video/*, image/*"}
                                style={{ display: "none" }}
                                onChange={handleFileUploaded}
                            />
                            <label htmlFor={`upload-media-${platform}-${type}`}>
                                <Button
                                    variant="outlined"
                                    component="span"
                                    disabled={isUploading || isSaving}
                                >
                                    {isUploading
                                        ? "Uploading..."
                                        : assetData?.media
                                        ? "Re-upload attachment"
                                        : "Upload attachment"}
                                </Button>
                            </label>
                            {!!assetData?.media && (
                                <Button onClick={handleClearMedia}>Clear asset media</Button>
                            )}
                        </Flex>
                    </FlexColumn>
                )}
            </AccordionDetails>
        </Accordion>
    )
}

export default AssetAccordion
