import React, { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { ReactComponent as CopyIcon } from "@queue-dev/ui-components/dist/assets/icons/copy.svg"
import { ReactComponent as ArrowIcon } from "@queue-dev/ui-components/dist/assets/icons/keyboard-arrow.svg"
import { ReactComponent as CreateNewIcon } from "@queue-dev/ui-components/dist/assets/icons/addIcon.svg"
import TextField from "@mui/material/TextField"
import { capitalizeFirstLetter } from "../../../utils/dataProcessing"
import { createPromptSchema } from "./from-validation-schema"
import { Button, BUTTON_TYPE, Flex, FlexColumn, H5 } from "@queue-dev/ui-components"
import {
    Divider,
    FlexRow,
    FormContainer,
    FormFieldsWrapper,
    FormWrapper,
    SubmitButtonWrapper,
    TestPanel,
} from "./ManagePromptForm.styles"
import ControlledAutocomplete from "../ControlledAutocomplete/ControlledAutocomplete"
import {
    GeneratedTextResultType,
    ICreatePrompt,
    IEditPrompt,
    IPrompt,
    IPublishPromptRequest,
} from "../../../fetchers/promptFetcher/interfaces"
import { InferType } from "yup"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import TestPromptSubForm from "./TestPromptSubForm"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"

const DEFAULT_VALUES: InferType<typeof createPromptSchema> = {
    code: "",
    completion: undefined,
    phrase: "",
    systemMessage: "",
    version: undefined,
    temperature: 1,
    maxTokens: 256,
    topP: 1,
    frequencyPenalty: 0,
    presencePenalty: 0,
    model: "gpt-4",
    resultType: GeneratedTextResultType.html,
}

interface ICreatePromptFormProps {
    handleFormSubmit: (data: ICreatePrompt | IEditPrompt) => Promise<IPrompt>
    promptVersions: IPrompt[]
    label: string
    onPublishVersion: (data: IPublishPromptRequest) => Promise<void>
}

const ManagePromptForm = ({
    handleFormSubmit,
    promptVersions = [],
    onPublishVersion,
    label,
}: ICreatePromptFormProps) => {
    const [isTestPanelExpanded, setIsTestPanelExpanded] = useState(false)
    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isSubmitting, defaultValues: formDefaultValues, isDirty },
        watch,
        reset,
    } = useForm({ resolver: yupResolver(createPromptSchema) })
    const { code, version = 0, completion, resultType = DEFAULT_VALUES.resultType } = watch()
    const isCurrVersionInUse = useMemo(
        () => promptVersions.find((p) => p.version === +version)?.inUse,
        [promptVersions, version]
    )

    const versionOptions = useMemo(() => {
        const options = promptVersions
            .sort((p1, p2) => +p2.version - +p1.version)
            .map(({ version, inUse }) => ({
                label: `${inUse ? "★ " : ""}${version} ${inUse ? "(Active)" : ""}`,
                value: `${version}`,
            }))
        if (version) {
            return options
        }
        return [{ label: "New", value: undefined }, ...options]
    }, [promptVersions, version])

    useEffect(() => {
        if (!promptVersions.length) {
            reset({ ...DEFAULT_VALUES, code: label })
            return
        }
        if (!formDefaultValues) {
            reset(
                promptVersions[promptVersions?.findIndex(({ inUse }) => inUse)] || promptVersions[0]
            )
        } else if (version && +version !== formDefaultValues.version) {
            const newPrompt = promptVersions?.find((p) => p.version === +version)
            reset({ ...DEFAULT_VALUES, ...newPrompt })
        }
    }, [version])

    const handlePromptFormSubmit = async (data: ICreatePrompt) => {
        const { version, ...rest } = data
        if (version) {
            await handleFormSubmit(data)
        } else {
            const response = await handleFormSubmit({ ...rest })
            reset(response)
        }
    }

    return (
        <FormWrapper>
            <FormContainer>
                <FlexColumn
                    as="form"
                    onSubmit={handleSubmit(handlePromptFormSubmit)}
                    overflow="hidden"
                >
                    <Flex justifyContent="space-between" alignItems="center" pb={4} my={3} px={4}>
                        <H5>
                            {promptVersions.length ? "Edit" : "New"} Prompt -
                            {` ${label.charAt(0).toUpperCase()}${label.slice(1)}`}
                        </H5>
                        {!!promptVersions.length && (
                            <Flex alignItems="center" gap={20}>
                                <ControlledAutocomplete
                                    control={control}
                                    name="version"
                                    size="small"
                                    options={versionOptions}
                                    errorMessage={errors?.version?.message || ""}
                                    label="Version"
                                    placeholder=""
                                    sx={{ minWidth: 165 }}
                                    clearable={false}
                                />
                                <Flex gap={10}>
                                    {!!version && (
                                        <>
                                            <Tooltip title="Duplicate as new version" arrow>
                                                <IconButton
                                                    onClick={() =>
                                                        reset({
                                                            ...promptVersions?.find(
                                                                (p) => p.version === +version
                                                            ),
                                                            version: DEFAULT_VALUES.version,
                                                        })
                                                    }
                                                >
                                                    <CopyIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="New empty version" arrow>
                                                <IconButton
                                                    onClick={() =>
                                                        reset({ ...DEFAULT_VALUES, code })
                                                    }
                                                >
                                                    <CreateNewIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    )}
                                </Flex>
                            </Flex>
                        )}
                    </Flex>
                    <FormFieldsWrapper>
                        <Flex overflow="hidden">
                            <Flex flex={2} overflow="auto">
                                <FlexColumn flex={1} pt={2} gap={20}>
                                    <TextField
                                        label="Model"
                                        error={!!errors.model?.message}
                                        helperText={capitalizeFirstLetter(errors?.model?.message)}
                                        size="small"
                                        {...register("model")}
                                    />
                                    <FormControl fullWidth>
                                        <InputLabel
                                            id="result-type"
                                            shrink
                                            sx={{ background: "#fff" }}
                                        >
                                            Result Type
                                        </InputLabel>
                                        <Select
                                            value={resultType}
                                            size="small"
                                            labelId="result-type"
                                            label="Result Type"
                                            {...register("resultType")}
                                        >
                                            {Object.values(GeneratedTextResultType).map((value) => (
                                                <MenuItem key={value} value={value}>
                                                    {value.toUpperCase()}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FlexRow>
                                        <TextField
                                            error={!!errors.maxTokens?.message}
                                            helperText={capitalizeFirstLetter(
                                                errors?.maxTokens?.message
                                            )}
                                            placeholder="Max Tokens"
                                            label="Max Tokens"
                                            type="number"
                                            size="small"
                                            InputProps={{ inputProps: { min: 100, max: 6000 } }}
                                            {...register("maxTokens")}
                                        />
                                        <TextField
                                            error={!!errors.temperature?.message}
                                            helperText={capitalizeFirstLetter(
                                                errors?.temperature?.message
                                            )}
                                            placeholder="Temperature (0-1)"
                                            label="Temperature"
                                            type="number"
                                            size="small"
                                            InputProps={{
                                                inputProps: { min: 0, max: 2, step: 0.1 },
                                            }}
                                            {...register("temperature")}
                                        />
                                    </FlexRow>
                                    <FlexRow>
                                        <TextField
                                            error={!!errors.presencePenalty?.message}
                                            helperText={capitalizeFirstLetter(
                                                errors?.presencePenalty?.message
                                            )}
                                            placeholder="Presence Penalty"
                                            label="Presence Penalty"
                                            type="number"
                                            size="small"
                                            InputProps={{
                                                inputProps: { min: 0, max: 2, step: 0.01 },
                                            }}
                                            {...register("presencePenalty")}
                                        />
                                        <TextField
                                            error={!!errors.frequencyPenalty?.message}
                                            helperText={capitalizeFirstLetter(
                                                errors?.frequencyPenalty?.message
                                            )}
                                            placeholder="Frequency Penalty"
                                            label="Frequency Penalty"
                                            type="number"
                                            size="small"
                                            InputProps={{
                                                inputProps: { min: 0, max: 2, step: 0.01 },
                                            }}
                                            {...register("frequencyPenalty")}
                                        />
                                    </FlexRow>
                                    <TextField
                                        error={!!errors.topP?.message}
                                        helperText={capitalizeFirstLetter(errors?.topP?.message)}
                                        placeholder="Top P"
                                        label="Top P"
                                        type="number"
                                        size="small"
                                        InputProps={{ inputProps: { min: 0, max: 2, step: 0.1 } }}
                                        {...register("topP")}
                                    />
                                </FlexColumn>
                                <FlexColumn flex={2} pt={2} ml="20px" pr="20px" gap={20} pb={5}>
                                    <TextField
                                        error={!!errors.systemMessage?.message}
                                        helperText={capitalizeFirstLetter(
                                            errors?.systemMessage?.message
                                        )}
                                        placeholder="System message"
                                        label="System message"
                                        size="small"
                                        multiline
                                        rows={7}
                                        {...register("systemMessage")}
                                    />
                                    <TextField
                                        error={!!errors.phrase?.message}
                                        helperText={capitalizeFirstLetter(errors?.phrase?.message)}
                                        placeholder="User message / Phrase"
                                        label="User message / Phrase"
                                        size="small"
                                        multiline
                                        rows={7}
                                        {...register("phrase")}
                                    />
                                    {completion && (
                                        <TextField
                                            error={!!errors.completion?.message}
                                            helperText={capitalizeFirstLetter(
                                                errors?.completion?.message
                                            )}
                                            placeholder="Completion"
                                            label="Completion (Legacy)"
                                            size="small"
                                            {...register("completion")}
                                        />
                                    )}
                                </FlexColumn>
                            </Flex>
                            <TestPanel $extend={isTestPanelExpanded}>
                                <Divider flexShrink={0}>
                                    <IconButton
                                        onClick={() => setIsTestPanelExpanded(!isTestPanelExpanded)}
                                    >
                                        <ArrowIcon
                                            style={
                                                isTestPanelExpanded
                                                    ? {}
                                                    : { transform: "rotate(180deg)" }
                                            }
                                        />
                                    </IconButton>
                                </Divider>
                                <TestPromptSubForm
                                    code={code}
                                    version={version}
                                    disabled={isDirty || !version}
                                />
                            </TestPanel>
                        </Flex>
                    </FormFieldsWrapper>
                    <SubmitButtonWrapper>
                        {!!version && (
                            <Button
                                type="button"
                                onClick={() => onPublishVersion({ code, version })}
                                buttonType={BUTTON_TYPE.FILLED}
                                autoLoading
                                disabled={isCurrVersionInUse}
                            >
                                Activate
                            </Button>
                        )}
                        {!version && (
                            <Tooltip
                                title="Saving the new configuration *without* activate it"
                                placement="top"
                                arrow
                            >
                                <Flex>
                                    <Button
                                        type="submit"
                                        loading={isSubmitting}
                                        buttonType={BUTTON_TYPE.FILLED}
                                        rightIcon={<ArrowIcon height={16} />}
                                        style={{ paddingRight: 8 }}
                                        color="secondary"
                                        disabled={!isDirty && !!version}
                                    >
                                        Create
                                    </Button>
                                </Flex>
                            </Tooltip>
                        )}
                    </SubmitButtonWrapper>
                </FlexColumn>
            </FormContainer>
        </FormWrapper>
    )
}

export default ManagePromptForm
