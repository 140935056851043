import { MediaType } from "../fetchers/assetsFetcher/interfaces"

export const getMediaType = (fileExtension: string): MediaType | undefined => {
    switch (fileExtension) {
        case "mp3":
        case "wav":
        case "ogg":
            return MediaType.Audio
        case "jpg":
        case "jpeg":
        case "png":
        case "webp":
            return MediaType.Image
        case "mp4":
        case "mov":
        case "avi":
        case "wmv":
        case "flv":
        case "mkv":
        case "webm":
            return MediaType.Video
        default:
            return undefined
    }
}

export function b64toBlob(b64Data: string, contentType = "", sliceSize = 512) {
    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: contentType })
}

export const base64ToFile = (base64: string, filename: number | string) => {
    const block = base64.split(";")
    const contentType = block[0].split(":")[1]
    const realData = block[1].split(",")[1]
    const blob = b64toBlob(realData, contentType)
    const extension = contentType.split("/")[1]
    const composedFileName = `${filename}.${extension}`
    return new File([blob], composedFileName, { type: contentType })
}

export const urlToFile = async (imageUrl: string, filename: number | string) => {
    const response = await fetch(imageUrl, {
        cache: "no-cache",
        mode: "cors",
        credentials: "omit",
    })
    const blob = await response.blob()
    const contentType = blob.type
    const extension = contentType.split("/")[1]
    const composedFileName = `${filename}.${extension}`
    return new File([blob], composedFileName, { type: contentType })
}
