import { useQuery } from "@tanstack/react-query"
import { QUERY_KEYS } from "../config/query-client"
import { answerFetcher } from "../fetchers/answerFetcher/answerFetcher"
import { assetsFetcher } from "../fetchers/assetsFetcher/assetsFetcher"
import { IAnswerDetails } from "../fetchers/answerFetcher/interfaces"
import { IAsset } from "../fetchers/assetsFetcher/interfaces"

export interface IAssetsByAnswerResponse {
    answerDetails: IAnswerDetails
    assets: IAsset[]
}

const useGetAssetsByAnswerQuery = (answerId: string) => {
    return useQuery<IAssetsByAnswerResponse>(
        QUERY_KEYS.getAssetsByAnswer(answerId),
        async () => {
            const answerDetails = await answerFetcher.getAnswerDetailsById(answerId)
            const assets = await assetsFetcher.getAssetsByAnswerId(answerId)
            return { answerDetails, assets }
        },
        {
            enabled: !!answerId,
        }
    )
}

export default useGetAssetsByAnswerQuery
