import { useMutation, useQueryClient } from "@tanstack/react-query"
import { QUERY_KEYS } from "../config/query-client"
import { promptFetcher } from "../fetchers"
import { IEditPrompt, IPrompt, IPromptsFilterParams } from "../fetchers/promptFetcher/interfaces"
import { Toast } from "@queue-dev/ui-components"

const useUpdatePromptMutation = (filterParams?: IPromptsFilterParams) => {
    const queryClient = useQueryClient()

    return useMutation<any, any[], IEditPrompt, any>(
        (changes) => promptFetcher.updatePrompt(changes),
        {
            onMutate: async () => {
                await queryClient.cancelQueries(QUERY_KEYS.getPrompts(filterParams))
            },
            onError: () => {
                Toast.error("There was an error while updating prompt")
            },
            onSuccess: (res, input) => {
                Toast.success("Prompt updated successfully")
                queryClient.setQueryData<IPrompt[]>(
                    QUERY_KEYS.getPrompts(filterParams),
                    (prompts) =>
                        prompts?.map((prompt) =>
                            prompt.code !== input.code || prompt.version !== input.version
                                ? prompt
                                : {
                                      ...input,
                                      updatedAt: new Date(),
                                      createdAt: new Date(),
                                      inUse: false,
                                  }
                        )
                )
                queryClient.invalidateQueries(QUERY_KEYS.getPrompts(filterParams))
            },
        }
    )
}

export default useUpdatePromptMutation
