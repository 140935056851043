import React, { createContext, useState, ReactNode } from "react"

interface HttpStatusMessageContextType {
    message: string | null
    messageType: string | null
    showMessage: (text: string, httpStatus?: number) => void
    hideMessage: () => void
}

export const HttpStatusMessageContext = createContext<HttpStatusMessageContextType>({
    message: null,
    messageType: null,
    showMessage: () => {},
    hideMessage: () => {},
})

export enum HttpStatusType {
    WARN = "warn",
    ERROR = "error",
    SUCCESS = "success",
}

interface HttpStatusMessageProviderProps {
    children: ReactNode
}

export const HttpStatusMessageProvider: React.FC<HttpStatusMessageProviderProps> = ({
    children,
}) => {
    const [message, setMessage] = useState<string | null>(null)
    const [messageType, setMessageType] = useState<string | null>(null)

    const showMessage = (text: string, httpStatus?: number) => {
        httpStatus = httpStatus || 200
        setMessage(text)
        if (httpStatus >= 200 && httpStatus < 300) {
            setMessageType(HttpStatusType.SUCCESS)
        } else if (httpStatus >= 400 && httpStatus < 500) {
            setMessageType(HttpStatusType.WARN)
        } else if (httpStatus >= 500 && httpStatus < 600) {
            setMessageType(HttpStatusType.ERROR)
        }
        setTimeout(() => {
            hideMessage()
        }, 3000)
    }

    const hideMessage = () => {
        setMessage(null)
        setMessageType(null)
    }

    return (
        <HttpStatusMessageContext.Provider
            value={{ message, messageType, showMessage, hideMessage }}
        >
            {children}
        </HttpStatusMessageContext.Provider>
    )
}
