import { useMutation, useQueryClient } from "@tanstack/react-query"
import { IPromptsFilterParams, IPublishPromptRequest } from "../fetchers/promptFetcher/interfaces"
import { promptFetcher } from "../fetchers"
import { QUERY_KEYS } from "../config/query-client"
import { Toast } from "@queue-dev/ui-components"

const usePublishPromptMutation = (filterParams?: IPromptsFilterParams) => {
    const queryClient = useQueryClient()

    return useMutation<any, any[], IPublishPromptRequest, any>(
        (changes) => promptFetcher.publishPrompt(changes),
        {
            onMutate: async () => {
                await queryClient.cancelQueries(QUERY_KEYS.getPrompts(filterParams))
            },
            onError: () => {
                Toast.error("There was an error while publishing prompt")
            },
            onSuccess: () => {
                Toast.success("Prompt published successfully")
                queryClient.invalidateQueries(QUERY_KEYS.getPrompts(filterParams))
            },
        }
    )
}

export default usePublishPromptMutation
