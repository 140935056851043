import React, { useId } from "react"
import { Body1, Flex } from "@queue-dev/ui-components"
import { Switch } from "@mui/material"
import { useToggleAssetReadyToPublish } from "../../../fetchers/assetsFetcher/mutations"

interface IReadyToPublishSwitchProps {
    answerId: string
    assetId?: string
    disabled: boolean
    defaultChecked: boolean
}

const ReadyToPublishSwitch = ({
    answerId,
    assetId,
    disabled,
    defaultChecked,
}: IReadyToPublishSwitchProps) => {
    const { mutate: toggleReadyToPublish } = useToggleAssetReadyToPublish(answerId)
    const id = useId()
    return (
        <Flex gap={0} onClick={(e) => e.stopPropagation()} alignItems="center">
            <Switch
                id={id}
                onChange={() => toggleReadyToPublish(assetId!)}
                disabled={disabled}
                defaultChecked={defaultChecked}
            />
            <Body1 as="label" htmlFor={id} color={disabled ? "#ccc" : undefined}>
                Available for user
            </Body1>
        </Flex>
    )
}

export default ReadyToPublishSwitch
