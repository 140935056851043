import React from "react"
import App from "./components/App/App"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import HttpStatusMessage from "./components/HttpStatusMessage/HttpStatusMessage"
import { HttpStatusMessageProvider } from "./components/HttpStatusMessage/HttpStatusMessageProvider"
import { QueryClientProvider } from "@tanstack/react-query"
import queryClient from "./config/query-client"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import Login from "./pages/Login"
import { PostAuthenticate } from "./pages/PostAuthenticate"
import GlobalStyles from "./styles/GlobalStyles"
import { AppThemeProvider } from "@queue-dev/ui-components"
import { CDN_URL } from "./constants/cdn"

const Public = () => {
    return (
        <AppThemeProvider cdnUrl={CDN_URL}>
            <GlobalStyles />
            <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                    <HttpStatusMessageProvider>
                        <HttpStatusMessage />
                        <GlobalStyles />
                        <Switch>
                            <Route path="/login" component={Login} />
                            <Route path="/authenticate" component={PostAuthenticate} />
                            <App />
                        </Switch>
                    </HttpStatusMessageProvider>
                    <ReactQueryDevtools position="bottom-right" />
                </QueryClientProvider>
            </BrowserRouter>
        </AppThemeProvider>
    )
}

export default Public
