import React, { useEffect, useState } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"
import clsx from "clsx"
import { authFetcher } from "../../fetchers"
import { ProfileResponse } from "../../fetchers/authFetcher/interfaces"
import styles from "./App.module.scss"
import AppRouter from "../../routes/AppRouter"
import QueueLoader from "../Loader/QueueLoader"
import LogRocket from "logrocket"
import { configureHandleUnAuthorizedError } from "../../fetchers/baseFetcher/baseFetcher"
import { ReactComponent as QueueLogo } from "@queue-dev/ui-components/dist/assets/icons/q-logo-black.svg"

const NavLinkItem = ({
    icon,
    to,
    children,
}: React.PropsWithChildren<{ icon: string; to: string }>) => {
    const { pathname } = useLocation()
    const active = to === pathname

    return (
        <li className={clsx("nav-item", styles.sideNavItem)}>
            <Link to={to} className={clsx("nav-link", active && "active")}>
                <i className={clsx("me-2", icon)}></i>
                <span>{children}</span>
            </Link>
        </li>
    )
}

const NavLinks = ({ handleClick }: { handleClick: () => void }) => {
    return (
        <ul className="nav nav-pills flex-column mb-auto" onClick={handleClick}>
            <NavLinkItem to="/" icon="bi-house">
                Home
            </NavLinkItem>
            <NavLinkItem to="/questions-upload" icon="bi-upload">
                Upload questions bulk
            </NavLinkItem>
            <NavLinkItem to="/prompt-manager" icon="bi-terminal">
                Prompt manager
            </NavLinkItem>
            {/* <NavLinkItem to="/trigger-integromat" icon="bi-power">Trigger Integromat</NavLinkItem> */}
        </ul>
    )
}

const MenuFooter = ({ profileData }: { profileData: ProfileResponse | undefined }) => {
    const history = useHistory()
    const handleSignOutClick = () => {
        authFetcher
            .logout()
            .catch(() => {})
            .finally(() => {
                history.push("/login")
            })
    }

    if (!profileData || !profileData.user) {
        return null
    }

    return (
        <div className="dropdown">
            <span
                className="d-flex align-items-center text-decoration-none dropdown-toggle clickable"
                data-bs-toggle="dropdown"
            >
                <img
                    src={profileData.user.img}
                    alt={profileData.user.name}
                    width="32"
                    height="32"
                    className="rounded-circle me-2"
                />
                <strong>{profileData.user.name}</strong>
            </span>
            <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
                <li>
                    <div className="dropdown-item clickable" onClick={handleSignOutClick}>
                        Sign out
                    </div>
                </li>
            </ul>
        </div>
    )
}

const App = () => {
    const [profileData, setProfileData] = useState<ProfileResponse | undefined>()
    const [isLoadingProfile, setIsLoadingProfile] = useState(true)
    const history = useHistory()
    const { pathname } = useLocation()

    const loadProfileData = async () => {
        try {
            const scopes = await authFetcher.getPermissions()
            if (!scopes.includes("user.backoffice_access")) {
                throw Error("No permissions")
            }
            const data = await authFetcher.getProfile()
            setProfileData(data)
            LogRocket.identify(data.user.userId, {
                name: data.user.name,
            })
        } catch (e) {
            history.replace("/login")
        }
    }

    useEffect(() => {
        configureHandleUnAuthorizedError(() => {
            localStorage.setItem("nextUrl", pathname)
            history.replace("/login")
        })
        setIsLoadingProfile(true)
        loadProfileData().finally(() => setIsLoadingProfile(false))
    }, [])

    const [menuOpen, setMenuOpen] = useState(false)

    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    }

    if (isLoadingProfile) {
        return <QueueLoader />
    }

    return (
        <div className={clsx(styles.pageContainer, "bg-light")}>
            <div className="d-sm-none container">
                <button onClick={toggleMenu} className={clsx("d-sm-none", styles.menuButton)}>
                    Backoffice
                </button>
            </div>
            <div
                className={clsx(
                    "d-flex flex-column flex-shrink-0 m-2 p-3",
                    styles.sideNav,
                    menuOpen && styles.sideNavOpen
                )}
            >
                <div className="d-flex align-items-center justify-content-between mb-3 mb-md-0 me-md-auto text-decoration-none">
                    <Link to="/" className="d-flex align-items-center text-decoration-none gap-2">
                        <div className={clsx("d-flex align-items-center", styles.logoWrapper)}>
                            <QueueLogo />
                        </div>
                        <span className="fs-4">Backoffice</span>
                    </Link>
                    <button
                        onClick={toggleMenu}
                        className={clsx("d-sm-none", styles.closeMenuButton)}
                    >
                        X
                    </button>
                </div>
                <hr />
                <NavLinks handleClick={toggleMenu} />
                <hr />
                <MenuFooter profileData={profileData} />
            </div>
            <div className={styles.routerContainer}>
                <AppRouter profileData={profileData} />
            </div>
        </div>
    )
}

export default App
