import { useState } from "react"
import { CellProps, Column } from "react-table"
import moment from "moment"
import { IPrompt, IPromptsByPlatformGroup } from "../../fetchers/promptFetcher/interfaces"
import { ReactComponent as EditIcon } from "@queue-dev/ui-components/dist/assets/icons/edit.svg"
import PromptPhraseCell from "./components/PromptPhraseCell/PromptPhraseCell"
import IconButton from "@mui/material/IconButton"
import { FlexColumn } from "@queue-dev/ui-components"

interface ColumnsProps {
    onEdit: (row: IPrompt) => void
}

export const columns = ({ onEdit }: ColumnsProps): Column<IPromptsByPlatformGroup>[] => [
    { Header: "Platform", accessor: "name" },
    {
        Header: "Prompt (Active)",
        Cell: ({
            cell: {
                row: { original: rowData },
            },
        }: CellProps<IPromptsByPlatformGroup>) => {
            const [isExpanded, setExpanded] = useState(false)
            const versionToDisplay =
                rowData.versions.find(({ inUse }) => inUse) ||
                rowData.versions[rowData.versions.length - 1]

            if (!versionToDisplay) return <>(empty)</>
            return (
                <PromptPhraseCell
                    onClick={() => setExpanded(!isExpanded)}
                    isExpanded={isExpanded}
                    prompt={versionToDisplay}
                />
            )
        },
    },
    {
        Header: "Last Updated",
        Cell: ({
            cell: {
                row: { original: rowData },
            },
        }: CellProps<IPromptsByPlatformGroup>) => {
            const versionToDisplay =
                rowData.versions.find(({ inUse }) => inUse) ||
                rowData.versions[rowData.versions.length - 1]

            if (!versionToDisplay) return null
            return (
                <FlexColumn minWidth={120}>
                    {moment(versionToDisplay.updatedAt).format("MM-DD-YYYY  HH:mm")}
                </FlexColumn>
            )
        },
    },
    {
        Header: "Versions",
        // @ts-ignore
        accessor: "versions.length",
    },
    {
        Header: " ",
        Cell: ({
            cell: {
                row: { original: rowData },
            },
        }: CellProps<IPromptsByPlatformGroup>) => {
            const versionToDisplay =
                rowData.versions.find(({ inUse }) => inUse) ||
                rowData.versions[rowData.versions.length - 1]

            return (
                <IconButton
                    onClick={() => onEdit(versionToDisplay || { code: rowData.code })}
                    sx={{ position: "relative", top: -4 }}
                >
                    <EditIcon />
                </IconButton>
            )
        },
    },
]
