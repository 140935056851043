import { BaseHttpFetcher } from "../baseFetcher/baseFetcher"
import { AdminLog } from "./interfaces"

export class AdminLogsFetcher extends BaseHttpFetcher {
    async getLogsById(logId: string): Promise<AdminLog | null> {
        try {
            return await this.get(`/admin/logs/${logId}`)
        } catch (err) {
            throw err
        }
    }

    async getLogsByType(logType: string): Promise<AdminLog[]> {
        try {
            return await this.get(`/admin/logs/all/${logType}`)
        } catch (err) {
            throw err
        }
    }

    async getLogsTypeByUser(logType: string, logUser: string): Promise<AdminLog[]> {
        try {
            return await this.get(`/admin/logs/all/user/${logType}/${logUser}`)
        } catch (err) {
            throw err
        }
    }
}
