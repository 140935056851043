import { BaseHttpFetcher } from "../baseFetcher/baseFetcher"

export class UnlimitedUsersFetcher extends BaseHttpFetcher {
    async getAll(): Promise<string[]> {
        try {
            return this.get(`/admin/unlimited-users`)
        } catch (err) {
            throw err
        }
    }

    async add(email: string): Promise<string[]> {
        try {
            return this.post(`/admin/unlimited-users`, { email })
        } catch (err) {
            throw err
        }
    }

    async remove(email: string): Promise<string[]> {
        try {
            return await this.delete(`/admin/unlimited-users/${email}`)
        } catch (err) {
            throw err
        }
    }
}
