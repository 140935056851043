import React, { useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import QueueLoader from "../../components/Loader/QueueLoader"
import { Toast } from "@queue-dev/ui-components"
import { authFetcher } from "../../fetchers"

export const PostAuthenticate = () => {
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        ;(async () => {
            const params = new URLSearchParams(location.search || "")
            const error = params.get("error")
            const code = params.get("code")
            if (error || !code) {
                Toast.error("Error signing in")
                history.replace("/")
                return
            }
            history.replace(location.pathname)
            try {
                await authFetcher.socialLoginStrategy(code)
            } catch (e) {
                Toast.error("Error signing in")
            }
            history.replace(localStorage.getItem("nextUrl") || "/")
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <QueueLoader />
}
