import { useQuill } from "react-quilljs"
import { useEffect, useMemo, useCallback } from "react"
import { TextArea } from "./RichTextEditor.styles"
import "quill/dist/quill.snow.css"
import Quill from "quill"

const quillOptions = (options?: object) => ({
    modules: {
        toolbar: [[{ header: [1, 2, 3, 4, false] }], ["bold", "italic", "underline"], ["link"]],
    },
    theme: "snow",
    placeholder: "Type in content..",
    ...options,
})

interface IMarkupAreaProps {
    defaultValue?: string
    readonly?: boolean
    onChange?: (html: string, text: string, quill: Quill) => void
    className?: string
    settings?: {
        [x: string]: any
    }
    label?: string
    height?: string
}

const RichTextEditor = ({
    onChange,
    defaultValue,
    className,
    settings,
    height = "auto",
}: IMarkupAreaProps) => {
    const id = useMemo(() => `rich-text-editor-${Math.floor(Math.random() * 100)}`, [])
    const quillSettings = useMemo(() => quillOptions({ ...settings, bounds: `#${id}` }), [settings])
    const { quill, quillRef } = useQuill(quillSettings)

    const handleContentChange = useCallback(() => {
        const text = quill?.getText().trim()
        onChange?.(quill?.root.innerHTML as string, text as string, quill as Quill)
    }, [onChange, quill])

    useEffect(() => {
        defaultValue && quill?.clipboard.dangerouslyPasteHTML(defaultValue)
        quill?.on("text-change", handleContentChange)
    }, [quill, defaultValue])

    useEffect(() => {
        quill?.on("text-change", handleContentChange)
        return () => {
            quill?.off("text-change", handleContentChange)
        }
    }, [handleContentChange, quill])

    return (
        <TextArea
            id={id}
            ref={quillRef}
            className={className}
            style={{
                height,
            }}
        />
    )
}

export default RichTextEditor
