import { IAnswerModel } from "./interfaces"
import { useMutation } from "@tanstack/react-query"
import { answerFetcher } from "./answerFetcher"
import { Toast } from "@queue-dev/ui-components"

export const useUpdateAnswerMutation = () => {
    return useMutation<any, unknown, Partial<IAnswerModel>>(
        async (answer) => answerFetcher.updateAnswer(answer),
        {
            onSuccess: () => {
                Toast.success("Answer saved")
            },
            onError: () => {
                Toast.error("Failed to update answer")
            },
        }
    )
}
