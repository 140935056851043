import React, { useMemo, useState } from "react"
import { useTable } from "react-table"
import { columns } from "./config"
import CircularProgress from "@mui/material/CircularProgress"
import { Modal } from "@queue-dev/ui-components"

import { MainContainer, SpinnerContainer, Table, TableContainer } from "./PromptManager.styles"
import ManagePromptForm from "../../components/PromptManager/ManagePromptForm/ManagePromptForm"
import { IEditPrompt } from "../../fetchers/promptFetcher/interfaces"
import useGetPrompts from "../../queries/use-get-prompts"
import useCreatePromptMutation from "../../mutations/use-create-prompt-mutation"
import useUpdatePromptMutation from "../../mutations/use-update-prompt-mutation"
import usePublishPromptMutation from "../../mutations/use-publish-prompt-mutation"
import styled from "styled-components"
import { groupBy } from "lodash"
import useGetPromptsPlatforms from "../../queries/use-get-prompts-platforms"

const WideModal = styled(Modal)<{ widthPercentage: number }>`
    min-width: ${({ widthPercentage }) => widthPercentage ?? 50}%;
    max-width: ${({ widthPercentage }) => widthPercentage || "initial"};
    padding: 0;
`

const PromptManager = () => {
    const { data: prompts = [], isLoading: loadingPrompts } = useGetPrompts()
    const { mutateAsync: createNewPrompt } = useCreatePromptMutation()
    const { mutateAsync: updatePrompt } = useUpdatePromptMutation()
    const { mutateAsync: publishPrompt } = usePublishPromptMutation()
    const { data: platforms, isLoading: loadingPlatforms } = useGetPromptsPlatforms()
    const [currEditPlatform, setCurrEditPlatform] = useState<string | undefined>()
    const isGettingPrompts = loadingPrompts || loadingPlatforms

    const columnsData = useMemo(
        () => columns({ onEdit: ({ code }) => setCurrEditPlatform(code) }),
        []
    )

    const promptsByPlatform = useMemo(() => groupBy(prompts, "code"), [prompts])
    const promptsByPlatformArray = useMemo(() => {
        return (platforms || [])
            .map(({ platform: name, code }) => ({
                code,
                name,
                versions: (promptsByPlatform[code] || []).sort((p1, p2) => p1.version - p2.version),
            }))
            .sort((plt1, plt2) => plt1.name.localeCompare(plt2.name))
    }, [platforms, promptsByPlatform])

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns: columnsData,
        data: promptsByPlatformArray,
    })

    return (
        <MainContainer>
            <h1>Prompts Manager</h1>
            <TableContainer>
                <Table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {isGettingPrompts ? (
                            <tr>
                                <td colSpan={columnsData.length}>
                                    <SpinnerContainer>
                                        <CircularProgress />
                                    </SpinnerContainer>
                                </td>
                            </tr>
                        ) : rows.length ? (
                            rows.map((row) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {cell.render("Cell")}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })
                        ) : (
                            <tr>
                                <td colSpan={columnsData.length}>No prompts found</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </TableContainer>
            <WideModal
                widthPercentage={66}
                isOpen={currEditPlatform !== undefined}
                close={() => setCurrEditPlatform(undefined)}
            >
                <ManagePromptForm
                    handleFormSubmit={async (data) => {
                        if (data?.version) {
                            return updatePrompt(data as IEditPrompt)
                        } else {
                            return createNewPrompt(data)
                        }
                    }}
                    label={currEditPlatform || ""}
                    promptVersions={promptsByPlatform[currEditPlatform || ""]}
                    onPublishVersion={publishPrompt}
                />
            </WideModal>
        </MainContainer>
    )
}
export default PromptManager
