import { BaseHttpFetcher } from "../baseFetcher/baseFetcher"
import { IAnswerDetails, IAnswerModel } from "./interfaces"

class AnswerFetcher extends BaseHttpFetcher {
    getAnswerDetailsById(id: string): Promise<IAnswerDetails> {
        return this.get<IAnswerDetails>(`/admin/answers/${id}/details`)
    }
    updateAnswer(answer: Partial<IAnswerModel>) {
        return this.post<IAnswerModel>(`/admin/answers`, answer)
    }
}

export const answerFetcher: AnswerFetcher = new AnswerFetcher()
