import React, { useState, useEffect } from "react"
import clsx from "clsx"
import { unlimitedUsersFetcher } from "../../fetchers"
import styles from "./UnlimitedUsersDisplay.module.scss"
import { Toast } from "@queue-dev/ui-components"

const EmailForm = ({ setData }: { setData: (data: string[]) => void }) => {
    const [email, setEmail] = useState<string>("")

    const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setEmail(e.target.value)
    }

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (
        e: React.FormEvent<HTMLFormElement>
    ) => {
        e.preventDefault()
        try {
            const newData = await unlimitedUsersFetcher.add(email)
            setEmail("")
            setData(newData)
            Toast.success(`The workspace of ${email} is now unlimited`)
        } catch (e) {
            Toast.error(`Failed to set ${email} workspace as unlimited`)
        }
    }

    return (
        <form className="d-flex justify-content-center align-items-center" onSubmit={handleSubmit}>
            <input
                type="email"
                className="form-control me-4"
                placeholder="User Email"
                value={email}
                onChange={handleEmailChange}
            />
            <button type="submit" className={styles.buttonPrimary}>
                Add
            </button>
        </form>
    )
}

const UnlimitedUsersDisplay = () => {
    const [unlimitedUsersData, setUnlimitedUsersData] = useState<string[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const loadData = async () => {
        setLoading(true)
        const data = await unlimitedUsersFetcher.getAll()
        console.log(data)
        setUnlimitedUsersData(data)
        setLoading(false)
    }

    const handleRemoveUser = async (email: string) => {
        const confirmed = window.confirm(
            `are you sure you want to remove user ${email} from unlimited credits?`
        )

        if (!confirmed) {
            return
        }

        try {
            await unlimitedUsersFetcher.remove(email)
            Toast.success(`The workspace of ${email} downgraded to the Free plan`)
            loadData()
        } catch (e) {
            Toast.error(`Failed to downgrade ${email}'s workspacew `)
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    let usersListElements = []
    if (unlimitedUsersData.length === 0) {
        return (
            <div className={clsx(styles.flexCenter, "px-4")}>
                <h4 className="mb-4">Add unlimited credits for user</h4>
                <div className={styles.container}>
                    <EmailForm setData={setUnlimitedUsersData} />
                    <hr />
                    <p>No users found</p>
                </div>
            </div>
        )
    } else {
        usersListElements = unlimitedUsersData.map((email) => (
            <div className="mb-2 d-flex justify-content-between align-items-center" key={email}>
                {email}
                <button className={styles.buttonPrimary} onClick={() => handleRemoveUser(email)}>
                    Remove
                </button>
            </div>
        ))
    }

    return (
        <div className={clsx(styles.flexCenter, "px-4")}>
            <h4 className="mb-4">Add unlimited credits for user</h4>
            <div className={styles.container}>
                <EmailForm setData={setUnlimitedUsersData} />
                <hr />
                {usersListElements}
                {loading ? "Loading..." : null}
            </div>
        </div>
    )
}

export default UnlimitedUsersDisplay
