import React, { useState } from "react"
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material"
import { ISavedQuestion } from "../../fetchers/savedQuestions/interfaces"
import { FlexColumn } from "@queue-dev/ui-components"

interface IUploadQuestionResultTableProps {
    uploadResult: string
    questions?: ISavedQuestion[]
}

const UploadQuestionResultTable = ({
    uploadResult,
    questions,
}: IUploadQuestionResultTableProps) => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    return (
        <FlexColumn>
            <Typography
                variant="caption"
                display="block"
                color="primary"
                style={{
                    margin: "10px 0 0 0",
                    fontWeight: "bold",
                    textAlign: "left",
                }}
            >
                {uploadResult}
            </Typography>
            {(questions || []).length > 0 && (
                <TableContainer component={Paper}>
                    <Table aria-label="questions table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Question</TableCell>
                                <TableCell>User ID</TableCell>
                                <TableCell>Workspace ID</TableCell>
                                <TableCell>Created At</TableCell>
                                <TableCell>Updated At</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Assign To</TableCell>
                                <TableCell>Source</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(questions || [])
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((question: ISavedQuestion, index: number) => (
                                    <TableRow key={index}>
                                        <TableCell
                                            style={{
                                                maxWidth: "300px",
                                                minWidth: "300px",
                                            }}
                                        >
                                            {question.scrapedData?.answer}
                                        </TableCell>
                                        <TableCell>{question.userId}</TableCell>
                                        <TableCell>{question.workspaceId}</TableCell>
                                        <TableCell>{question.createdAt?.toString()}</TableCell>
                                        <TableCell>{question.updatedAt?.toString()}</TableCell>
                                        <TableCell>{question.status}</TableCell>
                                        <TableCell>{question.assignTo}</TableCell>
                                        <TableCell>{question.source}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={questions?.length || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            )}
        </FlexColumn>
    )
}

export default UploadQuestionResultTable
