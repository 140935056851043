import { Controller, Control } from "react-hook-form"
import { Autocomplete, Theme } from "@mui/material"
import { AutocompleteValue } from "@mui/material/useAutocomplete/useAutocomplete"
import { SxProps } from "@mui/system"
import TextField from "@mui/material/TextField"
import { capitalizeFirstLetter } from "../../../utils/dataProcessing"
import React from "react"

interface IAutocompleteOption {
    label: string
    value?: string | boolean
}

interface IControlledAutocompleteProps {
    control: Control<any>
    name: string
    loading?: boolean
    options: IAutocompleteOption[]
    errorMessage: string
    placeholder: string
    label?: string
    defaultValue?: IAutocompleteOption
    disabled?: boolean
    size?: "small" | "medium"
    sx?: SxProps<Theme>
    clearable?: boolean
}

const ControlledAutocomplete = ({
    control,
    name,
    loading = false,
    options = [],
    errorMessage,
    placeholder,
    label = "",
    defaultValue,
    disabled,
    size,
    sx,
    clearable = true,
}: IControlledAutocompleteProps) => {
    const getFieldValue = (value: string | boolean) => {
        return options.find((option) => option.value === value) ?? null
    }
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <Autocomplete
                    {...field}
                    sx={sx}
                    size={size}
                    loading={loading}
                    disableClearable={!clearable}
                    options={options}
                    value={getFieldValue(field.value?.toString())}
                    onChange={(_, data: AutocompleteValue<any, any, any, any>) =>
                        field.onChange(data?.value)
                    }
                    defaultValue={defaultValue}
                    disabled={disabled}
                    renderInput={(params) => (
                        <TextField
                            label={label}
                            error={!!errorMessage}
                            helperText={capitalizeFirstLetter(errorMessage)}
                            placeholder={placeholder}
                            {...params}
                            InputLabelProps={{ shrink: true, ...params.InputLabelProps }}
                        />
                    )}
                />
            )}
        />
    )
}

export default ControlledAutocomplete
