import styled from "styled-components"
import { Flex, FlexColumn } from "@queue-dev/ui-components"

export const MainContainer = styled(FlexColumn)`
    overflow: hidden;
    flex-grow: 1;
    h1 {
        color: #5f5f5f;
        font-size: 1.8rem;
    }
`

export const TableContainer = styled.div`
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    padding: 20px;
`

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 14px;
    color: #333;
    line-height: 1.5;
    text-align: left;

    tbody td {
        vertical-align: top;
    }
    th,
    td {
        padding: 10px 15px;
    }
    th {
        font-weight: 600;
        color: #5f5f5f;
    }
    td {
        font-weight: 400;
        color: #8b92a8;
    }
    tr:last-child td {
        border-bottom: none;
    }
`

export const SpinnerContainer = styled(Flex)`
    width: 100%;
    justify-content: center;
    padding: 20px 0;
`

export const FilterContainer = styled(Flex)`
    width: 100%;
    margin: 20px 10px 20px 0;
    justify-content: space-between;
    align-items: center;
`

export const ContentBox = styled(FlexColumn)`
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 16px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    padding: 20px;
    overflow: hidden;
    flex-grow: 1;
`
