import { QueryClient } from "@tanstack/react-query"
import { IPromptsFilterParams, ITestPromptRequest } from "../fetchers/promptFetcher/interfaces"

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            retryOnMount: false,
            retry: false,
        },
    },
})

export const QUERY_KEYS = {
    getAssetsByAnswer: (answerId: string) => ["get-assets-by-answer", answerId],
    getPrompts: (filterParams?: IPromptsFilterParams) =>
        ["get-prompts", filterParams || false].filter(Boolean),
    getPromptsPlatforms: ["get-prompts-platforms"],
    getTestPrompt: (filters: ITestPromptRequest) => ["get-test-prompt", filters],
}

export default queryClient
