import { useMutation, useQueryClient } from "@tanstack/react-query"
import { QUERY_KEYS } from "../config/query-client"
import { promptFetcher } from "../fetchers"
import { ICreatePrompt, IPrompt, IPromptsFilterParams } from "../fetchers/promptFetcher/interfaces"
import { Toast } from "@queue-dev/ui-components"

const useCreatePromptMutation = (filterParams?: IPromptsFilterParams) => {
    const queryClient = useQueryClient()

    return useMutation<any, any[], ICreatePrompt, any>(
        (changes) => promptFetcher.createPrompt(changes),
        {
            onMutate: async () => {
                await queryClient.cancelQueries(QUERY_KEYS.getPrompts(filterParams))
            },
            onError: () => {
                Toast.error("There was an error while creating prompt")
            },
            onSuccess: (newPrompt) => {
                Toast.success("Prompt created successfully")
                queryClient.setQueryData<IPrompt[]>(
                    QUERY_KEYS.getPrompts(filterParams),
                    (oldData) => (oldData ? [...oldData, newPrompt] : [newPrompt])
                )
            },
        }
    )
}

export default useCreatePromptMutation
