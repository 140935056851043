import * as yup from "yup"
import { GeneratedTextResultType } from "../../../fetchers/promptFetcher/interfaces"

export const createPromptSchema = yup.object().shape({
    code: yup.string().required("Code is required"),
    version: yup.number().typeError("Number is required").optional(),
    model: yup.string().default(""),
    phrase: yup.string().required("Phrase is required"),
    systemMessage: yup.string().default(""),
    completion: yup.string(),
    resultType: yup.string().oneOf(Object.values(GeneratedTextResultType)).required(),
    maxTokens: yup
        .number()
        .typeError("Required as number")
        .label("Max tokens")
        .required()
        .min(100)
        .max(6000),
    temperature: yup
        .number()
        .typeError("Required as number")
        .required("Temperature is required")
        .min(0)
        .max(2),
    topP: yup.number().typeError("Required as number").required("Top P is required").min(0).max(1),
    frequencyPenalty: yup
        .number()
        .typeError("Required as number")
        .required("Frequency penalty is required")
        .min(0, "error")
        .max(2, "error"),
    presencePenalty: yup
        .number()
        .typeError("Required as number")
        .label("Presence Penalty")
        .required()
        .min(0)
        .max(2),
})
