import React, { useState } from "react"
import { Body2Light, Button, BUTTON_TYPE, Flex, FlexColumn } from "@queue-dev/ui-components"
import TextField from "@mui/material/TextField"
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import useTestPromptMutation from "../../../mutations/use-test-prompt-mutation"

interface ITestPromptSubFormProps {
    version: number
    code: string
    disabled: boolean
}

const TestPromptSubForm = ({ version, code, disabled }: ITestPromptSubFormProps) => {
    const [answerId, setAnswerId] = useState("")
    const { refetch: testPrompt, data: testResults } = useTestPromptMutation({
        version,
        code,
        answerId,
    })
    return (
        <FlexColumn gap={20} flexGrow={1}>
            <FlexColumn gap={10}>
                <Flex gap={20} alignItems="center">
                    <TextField
                        size="small"
                        label="Testing"
                        placeholder="Paste answerId here.."
                        sx={{ flexGrow: 1 }}
                        onChange={({ target: { value } }) => setAnswerId(value)}
                        value={answerId}
                    />
                    <Button
                        autoLoading
                        type="button"
                        buttonType={BUTTON_TYPE.FILLED}
                        onClick={() => testPrompt()}
                        disabled={disabled}
                    >
                        Test
                    </Button>
                </Flex>
                {disabled && (
                    <>
                        <Body2Light
                            alignSelf="stretch"
                            textAlign="center"
                            justifyContent="center"
                            color="#aaa"
                            width="100%"
                        >
                            - Please save the version before testing -
                        </Body2Light>
                        {testResults && (
                            <Body2Light
                                alignSelf="stretch"
                                textAlign="center"
                                justifyContent="center"
                                color="darkorange"
                            >
                                The results below related last saved prompt which has been changed
                            </Body2Light>
                        )}
                    </>
                )}
            </FlexColumn>
            {testResults && (
                <FlexColumn p={2} overflow="auto">
                    <Accordion>
                        <AccordionSummary>
                            <b>Prompt (with context)</b>
                        </AccordionSummary>
                        <AccordionDetails sx={{ background: "#fdf6ed" }}>
                            <FlexColumn style={{ whiteSpace: "pre-line" }}>
                                {testResults.prompt}
                            </FlexColumn>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary>
                            <b>Result</b>
                        </AccordionSummary>
                        <AccordionDetails sx={{ background: "#fdf6ed" }}>
                            <FlexColumn
                                dangerouslySetInnerHTML={{
                                    __html: testResults?.text,
                                }}
                            />
                        </AccordionDetails>
                    </Accordion>
                </FlexColumn>
            )}
        </FlexColumn>
    )
}

export default TestPromptSubForm
