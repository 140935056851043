import React, { useState } from "react"
import { Button, CircularProgress, TextField, Typography } from "@mui/material"
import { Search as SearchIcon } from "@mui/icons-material"
import { OwnWorkspace, workspaceFetcher } from "../../fetchers/workspaceFetcher/workspaceFetcher"
import { AxiosError } from "axios"
import { FlexColumn } from "@queue-dev/ui-components"

interface IWorkspaceSelectorProps {
    onChange: (workspace: OwnWorkspace) => void
}

const WorkspaceSelector = ({ onChange }: IWorkspaceSelectorProps) => {
    const [email, setEmail] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState("")

    const searchWorkspaceId = async () => {
        try {
            setIsLoading(true)
            const { workspaces } = await workspaceFetcher.getOwnWorkspaces(email)
            return onChange(workspaces[0])
        } catch (e) {
            if (e instanceof AxiosError) {
                if (e.response?.status === 404) {
                    setError("There are no workspaces for the above user")
                } else if (e.response?.status === 400) {
                    setError("Invalid email entered")
                }
            } else {
                console.error("error while fetching user workspaces", e)
                setError("Something went wrong while try to search workspace")
            }
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <FlexColumn gap={10}>
            <Typography variant="body2">
                Enter an email address and click the search button to find the user's owned
                workspace
            </Typography>
            <TextField
                label="Email"
                type="email"
                variant="outlined"
                size="small"
                helperText={error || " "}
                error={!!error}
                onChange={({ target: { value } }) => setEmail(value)}
                value={email}
            />
            <Button
                variant="contained"
                sx={{ alignSelf: "flex-start", textTransform: "none" }}
                startIcon={
                    isLoading ? <CircularProgress color="inherit" size={20} /> : <SearchIcon />
                }
                onClick={searchWorkspaceId}
                disabled={!email || isLoading}
            >
                Search workspace
            </Button>
        </FlexColumn>
    )
}

export default WorkspaceSelector
