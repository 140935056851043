import { BaseHttpFetcher } from "../baseFetcher/baseFetcher"
import {
    ICreatePrompt,
    IEditPrompt,
    IGetPromptsResponse,
    IPrompt,
    IPromptPlatform,
    IPromptsFilterParams,
    IPublishPromptRequest,
    ITestPromptRequest,
    ITestPromptResponse,
} from "./interfaces"

export class PromptFetcher extends BaseHttpFetcher {
    getAvailablePrompts(params: IPromptsFilterParams): Promise<IGetPromptsResponse> {
        return this.get("/admin/asset-text/prompt", undefined, params)
    }

    getAvailablePlatforms(): Promise<IPromptPlatform[]> {
        return this.get("/admin/asset-text/prompt/available-platforms")
    }

    createPrompt(prompt: ICreatePrompt): Promise<IPrompt> {
        return this.post("/admin/asset-text/prompt", prompt)
    }

    updatePrompt(prompt: IEditPrompt): Promise<IPrompt> {
        return this.put("/admin/asset-text/prompt", prompt)
    }

    publishPrompt(prompt: IPublishPromptRequest): Promise<IPrompt> {
        return this.post("/admin/asset-text/prompt/publish", prompt)
    }

    testPrompt(payload: ITestPromptRequest): Promise<ITestPromptResponse> {
        return this.post(`/admin/asset-text/${payload.answerId}/${payload.code}`, {
            provider: "openai",
            version: payload.version,
            isTest: true,
        })
    }
}
