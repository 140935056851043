import React from "react"
import { Global, css } from "@emotion/react"

const globalStyles = css`
    #root {
        height: 100vh;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    * {
        margin: 0 0;
        padding: 0 0;
        box-sizing: border-box;
    }
`
const GlobalStyles = () => <Global styles={globalStyles} />

export default GlobalStyles
