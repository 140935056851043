import axios from "axios"

class S3Fetcher {
    async uploadFileToS3(file: File, s3Url: string): Promise<any> {
        const response: any = await axios.put(s3Url, file, {
            headers: {
                "Content-Type": file.type,
            },
        })
        return response
    }
}

export const s3Fetcher: S3Fetcher = new S3Fetcher()
