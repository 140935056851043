import styled from "styled-components"
import { Body2, Flex, FlexColumn } from "@queue-dev/ui-components"

export const PromptCell = styled(Flex)<{ isExpanded: boolean }>`
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: ${({ isExpanded }) => (isExpanded ? "visible" : "hidden")};
    display: ${({ isExpanded }) => (isExpanded ? "block" : "-webkit-box")};
    -webkit-line-clamp: ${({ isExpanded }) => (isExpanded ? "unset" : "2")};
    -webkit-box-orient: vertical;
    transition: all 0.3s ease;
`

export const PromptParametersContainer = styled(FlexColumn)`
    width: 100%;
    gap: 10px;
    align-items: flex-start;
    margin-top: 10px;
    flex-wrap: wrap;
    height: 75px;
`

export const ParameterRow = styled(Flex)`
    gap: 10px;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.textPrimary};
`
export const ParameterHeading = styled(Body2)`
    padding-bottom: 5px;
    color: black;
    font-weight: 700;
`
