import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import moment from "moment"
import AdminLogsTable from "../AdminLogsTable/AdminLogsTable"
import { authFetcher } from "../../fetchers"
import { CreditsModel, ProfileResponse } from "../../fetchers/authFetcher/interfaces"
import styles from "./UserAdminLogsDisplay.module.scss"

const calcTotalCredits = (
    creditsKey: "scrapingStats" | "questionSaves" | "seoStats",
    credits: CreditsModel,
    creditsUsage: CreditsModel
) => {
    const creditsLeft = credits[creditsKey]
    const creditsUsed = creditsUsage[creditsKey]
    const totalCredits = creditsUsed + creditsLeft
    return { creditsLeft, creditsUsed, totalCredits }
}

const UserDisplay = ({
    profileData,
    loading,
}: {
    profileData: ProfileResponse | undefined
    loading: boolean
}) => {
    if (loading) {
        return <div>Loading user data...</div>
    }

    if (!profileData) {
        return <div>Received no user data</div>
    }

    const creditsScraping = calcTotalCredits(
        "scrapingStats",
        profileData.credits,
        profileData.creditsUsage
    )
    const creditsQuestionSaves = calcTotalCredits(
        "questionSaves",
        profileData.credits,
        profileData.creditsUsage
    )
    const creditsSeo = calcTotalCredits("seoStats", profileData.credits, profileData.creditsUsage)

    const createdAt = new Date(profileData.createdAt)
    const lastCreditsRefresh = new Date(profileData.creditsUsage.lastCreditsRefresh)
    const subscriptionProduct = profileData?.subscription?.product

    return (
        <div>
            <hr />
            <div className={styles.profileBox}>
                <img src={profileData.img} alt={profileData.name} />
                <div>{profileData.name}</div>
                <div>
                    Scraping: {creditsScraping.creditsUsed} / {creditsScraping.totalCredits}
                </div>
                <div>
                    Question saves: {creditsQuestionSaves.creditsUsed} /{" "}
                    {creditsQuestionSaves.totalCredits}
                </div>
                <div>
                    Seo: {creditsSeo.creditsUsed} / {creditsSeo.totalCredits}
                </div>
            </div>
            <hr />
            <div>
                <div>Premium: {profileData.premium ? "Yes" : "No"}</div>
                <div>Subscription: {subscriptionProduct || "Free"}</div>
                <div>
                    last credits refresh:{" "}
                    <span title={String(lastCreditsRefresh)}>
                        {moment(lastCreditsRefresh).fromNow()}
                    </span>
                </div>
                <div>
                    created: <span title={String(createdAt)}>{moment(createdAt).fromNow()}</span>
                </div>
                <div>locale: {profileData.locale}</div>
            </div>
            <hr />
        </div>
    )
}

const UserAdminLogsDisplay = () => {
    const { userId } = useParams<{ userId: string }>()
    const [profileData, setProfileData] = useState<ProfileResponse>()
    const [loading, setLoading] = useState<boolean>(false)

    const loadData = async () => {
        setLoading(true)
        const data = await authFetcher.getProfileByUserId(userId)
        setProfileData(data)
        setLoading(false)
    }

    useEffect(() => {
        loadData()
    }, [userId])

    return (
        <div>
            <UserDisplay profileData={profileData} loading={loading} />
            <div className="mb-4">
                <AdminLogsTable
                    logType="TRACK_SAVE_QUESTION"
                    logUser={userId}
                    title={`Question Saves - ${userId}`}
                    pageSize={5}
                />
            </div>
            <div className="mb-4">
                <AdminLogsTable
                    logType="TRACK_SEO_EXPOSE"
                    logUser={userId}
                    title={`Seo Exposes - ${userId}`}
                    pageSize={5}
                />
            </div>
            <div className="mb-4">
                <AdminLogsTable
                    logType="TRACK_LIMIT_REACH"
                    logUser={userId}
                    title={`Limits Reached - ${userId}`}
                    pageSize={5}
                />
            </div>
        </div>
    )
}

export default UserAdminLogsDisplay
