import { useMutation, useQueryClient } from "@tanstack/react-query"
import { assetsFetcher } from "./assetsFetcher"
import { AssetStatus, IAsset } from "./interfaces"
import omit from "lodash/omit"
import { QUERY_KEYS } from "../../config/query-client"
import { IAssetsByAnswerResponse } from "../../queries/use-get-assets-by-answer-query"
import { Toast } from "@queue-dev/ui-components"
import { ASSETS_CONFIG } from "../../components/Assets/assets-config"

export const useSaveAssetMutation = () => {
    const queryClient = useQueryClient()

    const getNameByPlatform = (platform = "", type = "") =>
        ASSETS_CONFIG.find((config) => config.platform === platform && config.type === type)
            ?.title || platform

    return useMutation<any, unknown, Partial<IAsset>>(
        (asset) => assetsFetcher.saveAsset(omit(asset, ["createdAt", "updatedAt", "__v"])),
        {
            onSuccess: (res, asset) => {
                queryClient.invalidateQueries(QUERY_KEYS.getAssetsByAnswer(asset.answerId!))
                Toast.success(
                    `${getNameByPlatform(asset.platform, asset.type)} asset ${
                        asset._id ? "updated" : "created"
                    }`
                )
            },
            onError: (err, asset) => {
                Toast.error(
                    `${getNameByPlatform(asset.platform, asset.type)} asset ${
                        asset._id ? "update" : "creation"
                    } failed`
                )
            },
        }
    )
}

export const useToggleAssetReadyToPublish = (answerId: string) => {
    const queryClient = useQueryClient()
    return useMutation<boolean, unknown, string>(
        (assetId) => assetsFetcher.toggleAssetReadyToPublish(assetId),
        {
            onSuccess: (res, assetId) => {
                Toast.success("Asset availability changed")
                queryClient.setQueryData<IAssetsByAnswerResponse>(
                    QUERY_KEYS.getAssetsByAnswer(answerId),
                    (prevState) => {
                        if (!prevState) return prevState
                        const { assets, answerDetails } = prevState
                        return {
                            answerDetails,
                            assets: assets?.map((asset) =>
                                asset._id !== assetId
                                    ? asset
                                    : {
                                          ...asset,
                                          status: res ? AssetStatus.READY_TO_PUBLISH : undefined,
                                      }
                            ),
                        }
                    }
                )
            },
            onError: () => {
                Toast.error("Failed to update asset availability")
            },
        }
    )
}
