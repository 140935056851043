import { useUploadMediaToAsset } from "../fetchers/s3Fetcher/mutations"
import { useState } from "react"
import { base64ToFile, urlToFile } from "../utils/file"
import { Toast } from "@queue-dev/ui-components"
import { ASSET_ATTACHMENTS_CDN } from "../constants/cdn"

interface IProcessedImage {
    id: string
    src: string
}

enum ImageSource {
    BASE64 = "base64",
    URL = "url",
}

export const MAX_IMAGE_SIZE = 2 ** 20 * 4 // 4mb

export const useImagesProcessor = (assetId: string) => {
    const { mutateAsync: uploadImageToS3 } = useUploadMediaToAsset(assetId)
    const [isUploading, setUploading] = useState(false)

    const handleImageUpload = async (
        image: HTMLImageElement,
        src: string,
        sourceType: ImageSource
    ): Promise<IProcessedImage | undefined> => {
        try {
            let imageFile
            const fileName = new Date().getTime()
            if (sourceType === ImageSource.BASE64) {
                imageFile = base64ToFile(src, fileName)
            } else {
                imageFile = await urlToFile(src, fileName)
            }

            return {
                id: image.id,
                src: (await uploadImageToS3(imageFile)).src,
            }
        } catch (e) {
            Toast.error("Unable to upload one of the images. Please submit your answer again.")
        }
    }

    const imageSourceValidator = (image: HTMLImageElement) => {
        const src = image.getAttribute("src") as string
        const srcType = src?.includes("data:image") ? ImageSource.BASE64 : ImageSource.URL
        const isValidSource = srcType === ImageSource.URL && src.startsWith(ASSET_ATTACHMENTS_CDN)
        return {
            src,
            srcType,
            isValidSource,
        }
    }

    const generateRandomId = () => {
        return String(
            Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15)
        )
    }

    const answerImagesProcessor = async (htmlContent: string): Promise<string> => {
        setUploading(true)
        const promises: Promise<IProcessedImage | undefined>[] = []
        const virtualDom = document.createElement("div")
        virtualDom.innerHTML = htmlContent
        const images = virtualDom.querySelectorAll("img")
        images.forEach((image) => image.setAttribute("id", generateRandomId()))

        if (!images) return htmlContent
        // @ts-ignore
        for (const image of images) {
            const { src, srcType, isValidSource } = imageSourceValidator(image)
            !isValidSource && promises.push(handleImageUpload(image, src, srcType))
        }

        await Promise.all(promises)
            .then((values) => {
                values.forEach((value) => {
                    const image = Array.from(images).find((image) => image.id === value?.id)
                    if (!value?.src) throw new Error("Image source is missing")
                    if (image) {
                        const newSource = value?.src
                        image.setAttribute("src", String(newSource))
                    }
                })
            })
            .finally(() => setUploading(false))

        return virtualDom.innerHTML
    }

    return {
        answerImagesProcessor,
        isUploading,
    }
}
