import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

export interface ApiResponseBody {
    status: boolean
    results: any
    errorId: string
    errorMessage: string
}

const BASE_URL = process.env.REACT_APP_API_URL

let handleUnauthorizedError: (error: AxiosError) => void

export const configureHandleUnAuthorizedError = (handleError: (error: AxiosError) => void) => {
    handleUnauthorizedError = handleError
}

export class BaseHttpFetcher {
    protected axios: AxiosInstance

    constructor(baseRoute?: string, fetcherBaseURL?: string) {
        let baseApiUrl = fetcherBaseURL ? fetcherBaseURL : BASE_URL
        baseApiUrl = baseRoute ? `${baseApiUrl}/${baseRoute}`.replace("//", "/") : baseApiUrl

        this.axios = axios.create({
            baseURL: baseApiUrl,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            withCredentials: true,
        })

        // Set interceptor on the response - Always return the "data" from the response
        this.axios.interceptors.response.use(
            (response: AxiosResponse<ApiResponseBody>) => {
                const apiResponse: ApiResponseBody = response.data?.results || response.data
                return apiResponse as any
            },
            function (error: any) {
                if (error instanceof AxiosError && error?.response?.status === 401) {
                    handleUnauthorizedError?.(error)
                }
                return Promise.reject(error)
            }
        )
    }

    protected get<T>(url: string, headers?: any, params?: any): Promise<T> {
        return this.axios.get(url, this.getBasicAxiosConfig({ params, headers }))
    }

    protected post<T>(url: string, body?: any, headers?: any, params?: any): Promise<T> {
        return this.axios.post(url, body, this.getBasicAxiosConfig({ params, headers }))
    }

    protected put<T>(url: string, body?: any, headers?: any): Promise<T> {
        return this.axios.put(url, body, this.getBasicAxiosConfig({ headers }))
    }

    protected delete<T>(url: string, headers?: any): Promise<T> {
        return this.axios.delete(url, this.getBasicAxiosConfig({ headers }))
    }

    private getBasicAxiosConfig(config?: AxiosRequestConfig): AxiosRequestConfig {
        return config || {}
    }
}
