import React from "react"
import { ReactComponent as ErrorIcon } from "../../assets/icons/error.svg"
import { useParams } from "react-router-dom"
import Quill from "quill"
import useGetAssetsByAnswerQuery from "../../queries/use-get-assets-by-answer-query"
import AnswerDetails from "./AnswerDetails/AnswerDetails"
import { ASSETS_CONFIG } from "./assets-config"
import AssetAccordion from "./AssetAccordion/AssetAccordion"
import { CircularProgress } from "@mui/material"
import { FlexColumn } from "@queue-dev/ui-components"
import styled from "styled-components"

export const extractTextFromHTML = (html: string) => {
    const tempCont = document.createElement("div")
    const quill = new Quill(tempCont)
    quill.clipboard.dangerouslyPasteHTML(html)
    return quill.getText().trim()
}

const MainContainer = styled(FlexColumn)`
    overflow: hidden;
    h1 {
        color: #5f5f5f;
        font-size: 1.8rem;
    }
`

const ContentBox = styled(FlexColumn)`
    width: 100%;
    margin: 20px 0;
    border: 1px solid #e0e0e0;
    border-radius: 16px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`

const Assets = () => {
    const { answerId } = useParams<{ answerId: string }>()
    const { data, error, isLoading } = useGetAssetsByAnswerQuery(answerId)
    const { answerDetails } = data || {}

    return (
        <MainContainer>
            <h1>Asset Manager - Attach Content to User Answer</h1>

            <ContentBox>
                <FlexColumn gap={50} padding="0 20px 10px 20px">
                    <>
                        {isLoading && (
                            <FlexColumn gap={10} alignItems="center" padding="40px 0">
                                <CircularProgress color="inherit" size={40} />
                            </FlexColumn>
                        )}
                        {error && (
                            <FlexColumn alignItems="center" gap={30} padding="40px 0">
                                <ErrorIcon />
                                <h3>Answer not found</h3>
                                <p>Please make sure you entered valid URL</p>
                            </FlexColumn>
                        )}
                        {answerDetails && (
                            <AnswerDetails
                                question={answerDetails.question}
                                workspaceName={answerDetails.workspaceName}
                                expertName={answerDetails.expertName}
                                answer={answerDetails.answer}
                            />
                        )}
                        {data?.assets && (
                            <FlexColumn gap={10}>
                                <h5>Assets</h5>
                                {ASSETS_CONFIG.map((config) => {
                                    const asset = data?.assets.find(
                                        ({ type, platform }) =>
                                            platform === config.platform && type === config.type
                                    )
                                    return (
                                        <AssetAccordion
                                            key={`${config.platform}${config.type}`}
                                            assetData={asset}
                                            answerId={answerId}
                                            userId={data.answerDetails.answer?.userId}
                                            workspaceId={data.answerDetails.answer?.workspaceId}
                                            {...config}
                                        />
                                    )
                                })}
                            </FlexColumn>
                        )}
                    </>
                </FlexColumn>
            </ContentBox>
        </MainContainer>
    )
}
export default Assets
