import { AuthFetcher } from "./authFetcher/authFetcher"
import { AdminLogsFetcher } from "./adminLogsFetcher/adminLogsFetcher"
import { UnlimitedUsersFetcher } from "./unlimitedUsersFetcher/unlimitedUsersFetcher"
import { IntegromatTriggersFetcher } from "./integromatTriggersFetcher/integromatTriggersFetcher"
import { TrialUsersFetcher } from "./trialUsersFetcher/trialUsersFetcher"
import { SubscriptionFetcher } from "./subscriptionFetcher/subscriptionFetcher"
import { SavedQuestions } from "./savedQuestions/savedQuestions"
import { PromptFetcher } from "./promptFetcher/promptFetcher"

export const authFetcher = new AuthFetcher()
export const adminLogsFetcher = new AdminLogsFetcher()
export const unlimitedUsersFetcher = new UnlimitedUsersFetcher()
export const trialUsersFetcher = new TrialUsersFetcher()
export const integromatTriggersFetcher = new IntegromatTriggersFetcher()
export const subscriptionFetcher = new SubscriptionFetcher()
export const savedQuestions = new SavedQuestions()
export const promptFetcher = new PromptFetcher()
