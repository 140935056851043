import { BaseHttpFetcher } from "../baseFetcher/baseFetcher"
import { SavedQuestionUploadResponse } from "./interfaces"

export class SavedQuestions extends BaseHttpFetcher {
    uploadQuestions(file: File, workspaceId: string): Promise<SavedQuestionUploadResponse> {
        const formData = new FormData()
        formData.append("file", file)
        formData.append("workspaceId", workspaceId)

        return this.post("/admin/savedQuestions/upload", formData, {
            "Content-Type": "multipart/form-data",
        })
    }
}
