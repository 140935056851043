export interface IPrompt {
    _id?: string
    code: string
    phrase: string
    systemMessage: string
    completion?: string
    maxTokens: number
    temperature: number
    version: number
    inUse: boolean
    updatedAt: Date
    createdAt: Date
    topP: number
    frequencyPenalty: number
    presencePenalty: number
    model: string
    resultType: GeneratedTextResultType
}

export enum GeneratedTextResultType {
    text = "text",
    html = "html",
}

export type ICreatePrompt = Omit<IPrompt, "inUse" | "updatedAt" | "createdAt" | "version"> & {
    version?: number
}

export type IEditPrompt = Omit<IPrompt, "inUse" | "updatedAt" | "createdAt">

export type IPromptPlatform = Pick<IPrompt, "code"> & {
    platform: string
}

export interface IGetPromptsResponse {
    result: IPrompt[]
}

export interface IPromptsFilterParams {
    code?: string
    version?: string
    inuse?: boolean
}

export type IPublishPromptRequest = Pick<IPrompt, "code" | "version">

export type ITestPromptProps = Pick<IPrompt, "code" | "version" | "model" | "phrase">

export interface ITestPromptForm extends ITestPromptProps {
    answerId: string
}

export type ITestPromptRequest = Omit<ITestPromptForm, "model" | "phrase">

export type ITestPromptResponse = {
    platform: string
    text: string
    prompt: string
}

export interface IPromptsByPlatformGroup {
    code: string
    name: string
    versions: IPrompt[]
}
