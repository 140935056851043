import React, { useState } from "react"
import { Button, Typography } from "@mui/material"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import StepContent from "@mui/material/StepContent"
import "./QuestionsUpload.scss"
import { savedQuestions } from "../../fetchers"
import {
    ISavedQuestion,
    SavedQuestionUploadResponse,
} from "../../fetchers/savedQuestions/interfaces"
import { OwnWorkspace } from "../../fetchers/workspaceFetcher/workspaceFetcher"
import WorkspaceSelector from "./WorkspaceSelector"
import UploadStep from "./UploadStep"
import UploadQuestionResultTable from "./UploadQuestionResultTable"
import { Flex, FlexColumn } from "@queue-dev/ui-components"
import styled from "styled-components"

enum STEPS {
    WORKSPACE,
    UPLOAD,
    RESULTS,
}

const MainContainer = styled(FlexColumn)`
    overflow: hidden;
    h1 {
        color: #5f5f5f;
        font-size: 1.8rem;
    }
`
const ContentBox = styled(FlexColumn)`
    width: 100%;
    margin: 20px 0;
    border: 1px solid #e0e0e0;
    border-radius: 16px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`

function QuestionsUpload() {
    const [questions, setQuestions] = useState<ISavedQuestion[]>([])
    const [uploadResult, setUploadResult] = useState("")
    const [activeStep, setActiveStep] = useState(STEPS.WORKSPACE)
    const [workspace, setWorkspace] = useState<OwnWorkspace | undefined>()

    const handleFileUploaded = async (file: File) => {
        const response: SavedQuestionUploadResponse = await savedQuestions.uploadQuestions(
            file,
            workspace!.id
        )
        if (response.savedQuestions.length > 0) {
            setQuestions(response.savedQuestions)
            setUploadResult(
                `${response.createdQuestions} questions saved successfully,\
                         and ${response.createdUsers} new users created`
            )
            setActiveStep(STEPS.RESULTS)
        }
    }

    const handleWorkspaceChanged = (ws: OwnWorkspace) => {
        setWorkspace(ws)
        setActiveStep(STEPS.UPLOAD)
    }

    const goToFirstStep = () => {
        setActiveStep(STEPS.WORKSPACE)
        setWorkspace(undefined)
    }

    return (
        <MainContainer>
            <FlexColumn style={{ maxWidth: 800 }}>
                <h1>Upload questions bulk</h1>
                <ContentBox>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        <Step>
                            <StepLabel>
                                <Typography variant="subtitle1">
                                    {`Select workspace ${workspace ? `(${workspace.name})` : ""}`}
                                </Typography>
                            </StepLabel>
                            <StepContent>
                                <WorkspaceSelector onChange={handleWorkspaceChanged} />
                            </StepContent>
                        </Step>
                        <Step>
                            <StepLabel>
                                <Typography variant="subtitle1">Upload spreadsheet</Typography>
                            </StepLabel>
                            <StepContent>
                                <UploadStep onBack={goToFirstStep} onUpload={handleFileUploaded} />
                            </StepContent>
                        </Step>
                        <Step>
                            <StepLabel>
                                <Flex justifyContent="space-between">
                                    <Typography variant="subtitle1">Uploaded results</Typography>
                                    {activeStep === STEPS.RESULTS && (
                                        <Button variant="contained" onClick={goToFirstStep}>
                                            Upload more
                                        </Button>
                                    )}
                                </Flex>
                            </StepLabel>
                            <StepContent>
                                <UploadQuestionResultTable
                                    uploadResult={uploadResult}
                                    questions={questions}
                                />
                            </StepContent>
                        </Step>
                    </Stepper>
                </ContentBox>
            </FlexColumn>
        </MainContainer>
    )
}

export default QuestionsUpload
