import { BaseHttpFetcher } from "../baseFetcher/baseFetcher"
import { ITrialUserAddRequest } from "./interfaces"

export class TrialUsersFetcher extends BaseHttpFetcher {
    async getAll(): Promise<string[]> {
        try {
            const trialUsers: any = await this.get(`/admin/users/trial`)
            return trialUsers.map((user: any) => user.userId)
        } catch (err) {
            throw err
        }
    }

    async add(data: ITrialUserAddRequest): Promise<string[]> {
        try {
            return await this.post(`/admin/users/invite/trial`, data)
        } catch (err) {
            throw err
        }
    }

    async remove(email: string): Promise<string[]> {
        try {
            return await this.delete(`/admin/trial-users/${email}`)
        } catch (err) {
            throw err
        }
    }
}
