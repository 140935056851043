import {
    AUTH0_AUDIENCE,
    AUTH0_CLIENT_ID,
    AUTH0_DOMAIN,
    AUTH0_LOGIN_REDIRECT_URI,
} from "../config/auth0"

export const getSocialAuthURL = (connection: "google-oauth2" | "twitter") => {
    const baseURL = `https://${AUTH0_DOMAIN!}/authorize`
    const params = new URLSearchParams({
        response_type: "code",
        client_id: AUTH0_CLIENT_ID!,
        redirect_uri: `${window.location.origin}${AUTH0_LOGIN_REDIRECT_URI}`,
        scope: "openid profile email offline_access",
        state: Math.floor(Math.random() * 10 ** 4).toString(),
        audience: AUTH0_AUDIENCE,
        connection,
    })
    return `${baseURL}?${params.toString()}`
}
