export enum AnswerType {
    TEXT = "TEXT",
    AUDIO = "AUDIO",
    VIDEO = "VIDEO",
}

export interface IAnswerModel {
    _id: string
    savedQuestionId: string
    userId: string
    workspaceId: string
    answerType: AnswerType
    contentLength?: number
    content?: string
    contentHTML?: string
    recordURL?: string
    recordDuration?: number
    __v?: number
    updatedAt?: string
    createdAt?: string
}

export interface IAnswerDetails {
    answerId: string
    question: string
    workspaceName: string
    expertName: string
    answer: IAnswerModel
}
