import { BaseHttpFetcher } from "../baseFetcher/baseFetcher"
import { IAsset } from "./interfaces"
import omit from "lodash/omit"

class AssetsFetcher extends BaseHttpFetcher {
    getAssetsByAnswerId(answerId: string): Promise<IAsset[]> {
        return this.get<IAsset[]>(`/admin/answers/${answerId}/assets`)
    }

    saveAsset(saveAsset: Partial<IAsset>): Promise<IAsset> {
        if (saveAsset._id) {
            return this.put<IAsset>(
                `/admin/answers/assets/${saveAsset._id}`,
                omit(saveAsset, [
                    "_id",
                    "workspaceId",
                    "userId",
                    "answerId",
                    "platform",
                    "status",
                    "contentUpdatedAt",
                    "contentUpdatedBy",
                ])
            )
        } else {
            return this.post<IAsset>(`/admin/answers/assets`, omit(saveAsset, ["workspaceId"]))
        }
    }

    getS3AssetMediaUrl(assetId: string, fileExtension: string): Promise<string> {
        return this.get<string>(`/admin/answers/assets/${assetId}/upload`, {}, { fileExtension })
    }

    toggleAssetReadyToPublish(assetId: string): Promise<boolean> {
        return this.post(`/admin/assets/${assetId}/readyToPublish`)
    }
}

export const assetsFetcher: AssetsFetcher = new AssetsFetcher()
