import { BaseHttpFetcher } from "../baseFetcher/baseFetcher"
import { ISubscriptionsResponse } from "./interfaces"

export class SubscriptionFetcher extends BaseHttpFetcher {
    async getAll(): Promise<ISubscriptionsResponse> {
        try {
            return await this.get(`/fastspring/subscriptions`)
        } catch (err) {
            throw err
        }
    }
}
