import { useMutation } from "@tanstack/react-query"
import { assetsFetcher } from "../assetsFetcher/assetsFetcher"
import { s3Fetcher } from "./s3Fetcher"
import { MediaType } from "../assetsFetcher/interfaces"
import { getMediaType } from "../../utils/file"

export const useUploadMediaToAsset = (assetId: string) => {
    return useMutation<{ src: string; type: MediaType | undefined }, unknown, File>(
        async (file) => {
            const extension = file.name.split(".").pop()!
            const s3Url = await assetsFetcher.getS3AssetMediaUrl(assetId, extension)
            const s3UploadResult = await s3Fetcher.uploadFileToS3(file, s3Url)
            return { src: s3UploadResult.config.url.split("?")[0], type: getMediaType(extension) }
        }
    )
}
