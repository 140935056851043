import { ProfileResponse } from "../fetchers/authFetcher/interfaces"
import clsx from "clsx"
import styles from "../components/App/App.module.scss"
import { Redirect, Route, Switch } from "react-router-dom"
import SingleAdminLogDisplay from "../components/SingleAdminLogDisplay/SingleAdminLogDisplay"
import AdminLogsTable from "../components/AdminLogsTable/AdminLogsTable"
import UserAdminLogsDisplay from "../components/UserAdminLogsDisplay/UserAdminLogsDisplay"
import UnlimitedUsersDisplay from "../components/UnlimitedUsersDisplay/UnlimitedUsersDisplay"
import TriggerIntegromatDisplay from "../components/TriggerIntegromatDisplay/TriggerIntegromatDisplay"
import React from "react"
import TrialUsersDisplay from "../components/TrialUsersDisplay/TrialUsersDisplay"
import QuestionsUpload from "../components/QuestionsUplaod/QuestionsUpload"
import Assets from "../components/Assets/Assets"
import PromptManager from "../pages/PromptManager/PromptManager"

const AppRouter = ({ profileData }: { profileData: ProfileResponse | undefined }) => {
    return (
        <div className={clsx("container", styles.mt4Desktop)}>
            <Switch>
                <Route path="/prompt-manager">
                    <PromptManager />
                </Route>
                <Route path="/assets/:answerId?">
                    <Assets />
                </Route>
                <Route path="/questions-upload">
                    <QuestionsUpload />
                </Route>
                <Route path="/admin-logs/:adminLogId">
                    <SingleAdminLogDisplay />
                </Route>
                <Route path="/new-users">
                    <AdminLogsTable logType="USER_FIRST_SIGN_IN" title="Q-Stats New Users" />
                </Route>
                <Route path="/on-boarding-keywords">
                    <AdminLogsTable
                        logType="ON_BOARDING_KEYWORD_UPDATE"
                        title="Q-Finder On Boarding"
                    />
                </Route>
                <Route path="/optimize-leads">
                    <AdminLogsTable logType="COLLECT_LEADS" title="Q-Stats Leads" />
                </Route>
                <Route path="/enterprise-leads">
                    <AdminLogsTable
                        logType="COLLECT_ENTERPRISE_LEADS"
                        title="Q-Stats Enterprise Leads"
                    />
                </Route>
                <Route path="/question-saves">
                    <AdminLogsTable logType="TRACK_SAVE_QUESTION" title="Question Saves" />
                </Route>
                <Route path="/seo-exposes">
                    <AdminLogsTable logType="TRACK_SEO_EXPOSE" title="Seo Exposes" />
                </Route>
                <Route path="/limit-reached">
                    <AdminLogsTable logType="TRACK_LIMIT_REACH" title="Limits Reached" />
                </Route>
                <Route path="/admin-logs-user/:userId">
                    <UserAdminLogsDisplay />
                </Route>
                <Route path="/unlimited-users">
                    <UnlimitedUsersDisplay />
                </Route>
                <Route path="/trial-users">
                    <TrialUsersDisplay />
                </Route>
                <Route path="/trigger-integromat">
                    <TriggerIntegromatDisplay />
                </Route>
                <Route path="/">
                    <div>Welcome {profileData?.user.name}</div>
                </Route>
                <Route path="*">
                    <Redirect to="/"></Redirect>
                </Route>
            </Switch>
        </div>
    )
}

export default AppRouter
