import { useQuery } from "@tanstack/react-query"
import { QUERY_KEYS } from "../config/query-client"
import { promptFetcher } from "../fetchers"
import { IPrompt, IPromptsFilterParams } from "../fetchers/promptFetcher/interfaces"
import _ from "lodash"

const useGetPrompts = (filterParams?: IPromptsFilterParams) => {
    return useQuery<IPrompt[]>(QUERY_KEYS.getPrompts(filterParams), async () => {
        const sortParamsWithoutEmptyFields = _.pickBy(filterParams, (value) => {
            return value !== ""
        })
        const { result } = await promptFetcher.getAvailablePrompts(sortParamsWithoutEmptyFields)
        return result
    })
}

export default useGetPrompts
