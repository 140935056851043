import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import moment from "moment"
import MaterialTable from "@material-table/core"
import { adminLogsFetcher } from "../../fetchers"
import { AdminLog } from "../../fetchers/adminLogsFetcher/interfaces"
import styles from "./AdminLogsTable.module.scss"

const isEmail = (value: string) => {
    if (!value || !value.match) {
        return false
    }

    return !!value.match(/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/)
}

const AdminLogsTable = ({
    logType,
    logUser,
    title,
    pageSize,
}: {
    logType: string
    logUser?: string
    title: string
    pageSize?: number
}) => {
    const [logsData, setLogsData] = useState<AdminLog[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const loadLogsData = async (logType: string, logUser: string | undefined) => {
        setLoading(true)
        const data = logUser
            ? await adminLogsFetcher.getLogsTypeByUser(logType, logUser)
            : await adminLogsFetcher.getLogsByType(logType)
        setLogsData(data)
        setLoading(false)
    }

    useEffect(() => {
        setLogsData([])
        loadLogsData(logType, logUser)
    }, [logType, logUser])

    const columnKeys = Array.from(
        new Set(logsData.map((logsData) => Object.keys(logsData.data)).flat())
    )
    const columns: any[] = columnKeys.map((columnKey) => {
        return {
            title: columnKey,
            field: columnKey,
            render: (rowData: any) => {
                const rowValue: string = rowData[columnKey]
                if (rowValue && String(rowValue).startsWith("http")) {
                    return (
                        <div className={styles.tableCol} title={rowValue}>
                            <a href={rowValue} target="_blank">
                                {rowValue}
                            </a>
                        </div>
                    )
                } else if (rowValue && isEmail(rowValue)) {
                    return (
                        <div className={styles.tableCol} title={rowValue}>
                            <Link to={`/admin-logs-user/${rowValue}`} target="_blank">
                                {rowValue}
                            </Link>
                        </div>
                    )
                }
                return (
                    <div className={styles.tableCol} title={rowValue}>
                        <Link to={`/admin-logs/${rowData.logId}`} target="_blank">
                            {rowValue}
                        </Link>
                    </div>
                )
            },
        }
    })

    const data = (logsData || []).map((logData) => {
        return { ...logData.data, date: logData.createdAt, logId: logData._id }
    })

    if (columns && columns.length) {
        // columns.unshift({
        //     filtering: false,
        //     searchable: false,
        //     align: 'center',
        //     export: false,
        //     field: 'logId',
        //     title: '',
        //     render: (rowData: any) => <a className="text-muted" href={`/#/admin-logs/${rowData.logId}`} target="_blank">{rowData.logId}</a>
        // });

        columns.push({
            field: "date",
            title: "Date",
            render: (rowData: any) => (
                <div className={styles.tableCol}>
                    <Link
                        to={`/admin-logs/${rowData.logId}`}
                        target="_blank"
                        title={new Date(rowData.date).toString()}
                    >
                        {moment(rowData.date).fromNow()}
                    </Link>
                </div>
            ),
        })
    }

    return (
        <div>
            <MaterialTable
                isLoading={loading}
                columns={columns}
                data={data}
                title={title}
                actions={[
                    {
                        icon: "refresh",
                        tooltip: "Refresh",
                        isFreeAction: true,
                        onClick: () => {
                            loadLogsData(logType, logUser)
                        },
                    },
                ]}
                options={{
                    filtering: true,
                    draggable: false,
                    pageSize: pageSize || 10,
                }}
            />
        </div>
    )
}

const AdminLogsTableContainer = ({
    logType,
    logUser,
    title,
    pageSize,
}: {
    logType: string
    logUser?: string
    title: string
    pageSize?: number
}) => {
    return (
        <div className={styles.tableContainer}>
            {/* <h2 className="text-center lead mb-3">{title} Data</h2> */}
            <AdminLogsTable logType={logType} logUser={logUser} title={title} pageSize={pageSize} />
        </div>
    )
}

export default AdminLogsTableContainer
