import { IPrompt } from "../../../../fetchers/promptFetcher/interfaces"
import { FlexColumn, Body2Light } from "@queue-dev/ui-components"
import {
    PromptCell,
    ParameterHeading,
    PromptParametersContainer,
    ParameterRow,
} from "./PromptPhraseCell.styles"

const PromptPhraseCell = ({
    isExpanded,
    prompt,
    onClick,
}: {
    isExpanded: boolean
    prompt: IPrompt
    onClick: () => void
}) => {
    const parameters = [
        {
            name: "Max Tokens",
            value: prompt.maxTokens,
        },
        {
            name: "Top P",
            value: prompt.topP,
        },
        {
            name: "Freq. Penalty",
            value: prompt.frequencyPenalty,
        },
        {
            name: "Presence Penalty",
            value: prompt.presencePenalty,
        },
        {
            name: "Temperature",
            value: prompt.temperature,
        },
    ]
    return (
        <FlexColumn onClick={onClick} flexGrow={1}>
            {prompt.systemMessage && (
                <PromptCell isExpanded={isExpanded} mb={isExpanded ? 2 : 0}>
                    <b>System: </b>
                    {prompt.systemMessage}
                </PromptCell>
            )}
            <PromptCell isExpanded={isExpanded}>
                <b>{prompt.systemMessage ? "User" : "Complete"}: </b>
                {prompt.phrase}
            </PromptCell>
            {isExpanded && (
                <PromptParametersContainer>
                    {parameters.map((parameter) => {
                        return !isNaN(parameter.value) ? (
                            <ParameterRow key={parameter.name}>
                                <ParameterHeading>{parameter.name}</ParameterHeading>
                                <Body2Light>{parameter.value}</Body2Light>
                            </ParameterRow>
                        ) : null
                    })}
                </PromptParametersContainer>
            )}
        </FlexColumn>
    )
}

export default PromptPhraseCell
