import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import clsx from "clsx"
import { adminLogsFetcher } from "../../fetchers"
import { AdminLog } from "../../fetchers/adminLogsFetcher/interfaces"
import styles from "./SingleAdminLogDisplay.module.scss"

const SingleAdminLogDisplay = () => {
    const { adminLogId } = useParams<{ adminLogId: string }>()
    const [logData, setLogData] = useState<AdminLog | null>()
    const [loading, setLoading] = useState<boolean>(false)

    const loadLogData = async (logId: string) => {
        setLoading(true)
        const data = await adminLogsFetcher.getLogsById(adminLogId)
        setLogData(data)
        setLoading(false)
    }

    useEffect(() => {
        loadLogData(adminLogId)
    }, [adminLogId])

    if (loading) {
        return <div>Loading...</div>
    }

    if (!logData) {
        return <div>No Data</div>
    }

    return (
        <div>
            <h2 className="text-center lead">Log data - {adminLogId}</h2>
            <pre className={clsx(styles.logContainer, "border")}>
                {JSON.stringify(logData, null, 4)}
            </pre>
        </div>
    )
}

export default SingleAdminLogDisplay
