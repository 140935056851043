import React from "react"
import Lottie from "lottie-react"
import * as loadingAnimationData from "../../assets/lotti/queue-loader.json"
import { FlexColumn } from "@queue-dev/ui-components"

interface IQueueLoaderProps {}

const QueueLoader = ({}: IQueueLoaderProps) => {
    return (
        <FlexColumn justifyContent="center" alignItems="center" flex={1}>
            <Lottie animationData={loadingAnimationData} loop={true} style={{ maxWidth: 500 }} />
        </FlexColumn>
    )
}

export default QueueLoader
