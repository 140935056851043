import { ITestPromptRequest } from "../fetchers/promptFetcher/interfaces"
import { useQuery } from "@tanstack/react-query"
import { promptFetcher } from "../fetchers"
import { QUERY_KEYS } from "../config/query-client"

const useTestPromptMutation = (filters: ITestPromptRequest) => {
    return useQuery(
        QUERY_KEYS.getTestPrompt(filters),
        async () => promptFetcher.testPrompt(filters),
        {
            enabled: false,
        }
    )
}

export default useTestPromptMutation
