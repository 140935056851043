import { useQuery } from "@tanstack/react-query"
import { QUERY_KEYS } from "../config/query-client"
import { promptFetcher } from "../fetchers"

const useGetPromptsPlatforms = () => {
    return useQuery<{ platform: string; code: string }[]>(QUERY_KEYS.getPromptsPlatforms, () =>
        promptFetcher.getAvailablePlatforms()
    )
}

export default useGetPromptsPlatforms
