import React, { useState, useEffect } from "react"
import { integromatTriggersFetcher } from "../../fetchers"
import { ITriggerData } from "../../fetchers/integromatTriggersFetcher/interfaces"
import styles from "./TriggerIntegromatDisplay.module.scss"

const TriggerIntegromatDisplay = () => {
    const [triggersData, setTriggersData] = useState<ITriggerData[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const loadData = async () => {
        setLoading(true)
        const data = await integromatTriggersFetcher.getAll()
        setTriggersData(data)
        setLoading(false)
    }

    useEffect(() => {
        loadData()
    }, [])

    const triggerListElements = triggersData.map((trigger) => (
        <div className="mb-3">
            <a
                target="_blank"
                href={integromatTriggersFetcher.getTriggerUrl(trigger)}
                key={trigger.key}
            >
                {trigger.description}
            </a>
        </div>
    ))

    return (
        <div className="px-4">
            <h4 className="mb-4 text-center">Trigger Integromat Event</h4>
            <div>
                {triggerListElements}
                {loading ? "Loading..." : null}
            </div>
        </div>
    )
}

export default TriggerIntegromatDisplay
