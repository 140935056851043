import { BaseHttpFetcher } from "../baseFetcher/baseFetcher"
import { ProfileResponse } from "./interfaces"

export class AuthFetcher extends BaseHttpFetcher {
    async getProfile(): Promise<ProfileResponse> {
        try {
            return await this.get("/users/profile")
        } catch (err) {
            throw err
        }
    }

    async getProfileByUserId(userId: string): Promise<ProfileResponse> {
        try {
            return await this.get(`/admin/users/${userId}`)
        } catch (err) {
            throw err
        }
    }

    async logout(): Promise<void> {
        return this.get("/auth/logout")
    }

    async getPermissions(): Promise<string[]> {
        try {
            return await this.get("/auth/permissions")
        } catch (err) {
            throw err
        }
    }

    async socialLoginStrategy(code: string) {
        return this.get("auth/oauth", undefined, { code })
    }
}
