import React, { useState, useEffect } from "react"
import clsx from "clsx"
import { subscriptionFetcher, trialUsersFetcher } from "../../fetchers"
import styles from "./TrialUsersDisplay.module.scss"
import {
    ISubscription,
    ISubscriptionsResponse,
} from "../../fetchers/subscriptionFetcher/interfaces"

const TrialUserForm = ({
    loadData,
    subscriptions,
}: {
    loadData: () => void
    subscriptions: ISubscription[]
}) => {
    const [email, setEmail] = useState<string>("")
    const [subscription, setSubscription] = useState<ISubscription>()
    const [answers, setAnswers] = useState<number>(0)
    const [days, setDays] = useState<number>(0)

    const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setEmail(e.target.value)
    }

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (
        e: React.FormEvent<HTMLFormElement>
    ) => {
        e.preventDefault()
        const formData = {
            email,
            answers,
            days,
            subscription: subscription?.path ?? "",
        }
        await trialUsersFetcher.add(formData)
        setEmail("")
        loadData()
    }

    const handleSubscriptionSelect = (subscription: ISubscription) => {
        setSubscription(subscription)
    }

    const handleAnswersChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAnswers(Number(e.target.value))
    }

    const handleDaysCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDays(Number(e.target.value))
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group ">
                <label htmlFor="inputEmail4">Email</label>
                <input
                    type="email"
                    className="form-control"
                    id="inputEmail4"
                    placeholder="Email"
                    onChange={handleEmailChange}
                />
            </div>
            <div className="form-group my-2">
                <label htmlFor="inputAnswers">Answers</label>
                <input
                    type="number"
                    className="form-control"
                    id="inputAnswers"
                    placeholder="Answers count"
                    onChange={handleAnswersChange}
                />
            </div>

            <div className="form-group my-2 d-flex align-center ">
                <div className="form-group">
                    <label htmlFor="inputDays">Days</label>
                    <input
                        type="number"
                        className="form-control"
                        id="inputDays"
                        placeholder="Days count"
                        onChange={handleDaysCountChange}
                    />
                </div>
                <div className="dropdown mx-4 ">
                    <p className="mb-0">Subscription type</p>
                    <button
                        className="btn btn-secondary dropdown-toggle clickable "
                        type="button"
                        id="dropdownMenu2"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        {subscription ? subscription.name : "Select"}
                    </button>
                    <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
                        {subscriptions.map((subscription) => (
                            <li>
                                <div
                                    className="dropdown-item clickable"
                                    onClick={() => handleSubscriptionSelect(subscription)}
                                >
                                    {subscription.name}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <button type="submit" className={styles.buttonPrimary}>
                Add
            </button>
        </form>
    )
}

const TrialUsersDisplay = () => {
    const [trialUsersData, setTrialUsersData] = useState<string[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [subscriptions, setSubscription] = useState<ISubscription[]>([])

    const formatSubscriptionsData = (subscriptions: ISubscriptionsResponse) => {
        const monthlySubscriptions = subscriptions.monthly
        const yearlySubscriptions = subscriptions.yearly.map((subscription) => ({
            ...subscription,
            name: `${subscription.name} (yearly)`,
        }))

        return monthlySubscriptions.concat(yearlySubscriptions)
    }
    const loadData = async () => {
        setLoading(true)
        const data = await trialUsersFetcher.getAll()
        const subscriptions = await subscriptionFetcher.getAll()
        const extractedSubscriptions = formatSubscriptionsData(subscriptions)
        setSubscription(extractedSubscriptions)
        setTrialUsersData(data)
        setLoading(false)
    }

    const handleRemoveUser = async (email: string) => {
        const confirmed = window.confirm(
            `are you sure you want to remove user ${email} from trial credits?`
        )

        if (!confirmed) {
            return
        }

        await trialUsersFetcher.remove(email)
        loadData()
    }

    useEffect(() => {
        loadData()
    }, [])

    let usersListElements = []
    if (trialUsersData.length === 0) {
        return (
            <div className={clsx(styles.flexCenter, "px-4")}>
                <h4 className="mb-4">Add trial credits for user</h4>
                <div className={styles.container}>
                    <TrialUserForm loadData={loadData} subscriptions={subscriptions} />
                    <hr />
                    <p>No users found</p>
                </div>
            </div>
        )
    } else {
        usersListElements = trialUsersData.map((email) => (
            <div className="mb-2 d-flex justify-content-between align-items-center" key={email}>
                {email}
                <button
                    hidden={true}
                    className={styles.buttonPrimary}
                    onClick={() => handleRemoveUser(email)}
                >
                    Remove
                </button>
            </div>
        ))
    }

    return (
        <div className={clsx(styles.flexCenter, "px-4")}>
            <h4 className="mb-4">Add trial credits for user</h4>
            <div className={styles.container}>
                <TrialUserForm loadData={loadData} subscriptions={subscriptions} />
                <hr />
                {usersListElements}
                {loading ? "Loading..." : null}
            </div>
        </div>
    )
}

export default TrialUsersDisplay
