import { BaseHttpFetcher } from "../baseFetcher/baseFetcher"

export interface OwnWorkspace {
    id: string
    name: string
    logo: string
}
export interface OwnWorkspaces {
    workspaces: OwnWorkspace[]
}

class WorkspaceFetcher extends BaseHttpFetcher {
    async getOwnWorkspaces(email: string): Promise<OwnWorkspaces> {
        return this.get(`/admin/workspaces/?email=${encodeURIComponent(email)}`)
    }
}

export const workspaceFetcher = new WorkspaceFetcher()
